.previewSpace{
    width: 80%;
    min-height: 300px;
    color: #495057 !important;
    font-weight: bolder;
    padding: 10px;
    line-height: 1.5;
    border : none;
}
.previewSection{
    border: 1px solid #4b4e511f;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
}
.editIconPreview{
    display: block;
    text-align: right;
    font-size: 18px;
}

.inputTextare{
    width: 80%;
    border: 1px solid #ebedf2;
    padding: 6px;
}
.pointerCursur{
    cursor: pointer;
}
.editIconPreview i{
    cursor: pointer;
}
.editIconPreviewConversation{
    position: absolute;
    bottom: 15px;
    right: 15px;
}
.posR{
    position: relative;
}