
.mmsdiv .form-group.row .kt-checkbox-list {
    width: 129px !important;
    margin-top: 3px;
}
.mmsdiv .kt-radio.inalign > span {
    border: 2px solid #d1d7e2;
}
.mmsdiv .kt-checkbox-list {
    padding: 0 12px;
}
.mmsdiv .kt-radio > span {
    border: 1px solid #d1d7e2;
}
.mmsdiv .kt-radio > span {
    background: none;
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50% !important;
}

.mmsarea button.yr-cancelbtn.linear-gradient-cancel.btn-sm.yr-btn-small {
    display: block;
}
.img-pos{
    position: relative;
}
.imgposdiv img{
    max-width: 70%;
}
.img-pos.close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    cursor: pointer;
}
.imgbox{
    width:320px;
    text-align: center;
    
}

#uploadtemplateID {
    cursor: pointer;
}
.imgobj > i{
    left: 320px !important;
    top:0 !important;
}
.imgbox img{
    width: 100%;
    object-fit: contain;
    margin: 0 auto;
}
.closebtn{
    position: absolute;
    left: 297px;
    top: -2px;
    font-size: 26px;
    cursor: pointer;
}
.imgobj{
    width: 315px;
    height: auto;
}

.mmsfile{
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
    opacity: 0;
    cursor: pointer !important;
    font-size: 0 !important;
}