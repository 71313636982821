.modal{
    background: rgba(0,0,0,0.4);
  }
  
  .modal-dialog {
    max-width: 700px;
  }
  .modal-header{
    border-bottom: 0;
    padding-bottom: 0;
  }
  .modal .modal-content {
    box-shadow: none !important;
  }
  .modal .modal-content .kt-portlet__foot{
    border-top: 0;
    padding: 20px 40px 40px 40px;
  }
  .modal .ui.form input[type=text], .modal .ui.input>input{    
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid hsl(227, 6%, 68%) !important;
    padding-left: 0px !important;
  }
  .modal .ui.form input[type=text]:focus, .modal .ui.input>input:focus{
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #aaacb3;
  }
  .modal .ui.selection.dropdown, .modal .ui.selection.dropdown:focus{
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #aaacb3;
    margin-left: 4px;
  }
 .modal .ui.multiple.search.dropdown>input.search{
    border-bottom: 0 !important;
  }
  .modal .modal-content .modal-header .modal-title {
    font-size: 1.8rem;
    padding: 1.25rem 0 0 1rem;
    color: #848588;
    font-weight: 500;   
  }
  .form-group label{
    margin-top: 10px;
    font-size: 1.12rem;
    font-weight: 500;
    color: #848588;
  }
  .modal .form-group {
    margin-bottom: 0rem;
  }
  .modal .kt-section{
    margin-bottom: 0;
  }
  .modal .kt-portlet .kt-portlet__body{
    padding-bottom: 0;
  }
  .modal-footer{
    border-top: 0;
  }
  .modal .ui.selection.active.dropdown .menu {
    border-color: #ffffff;
    }
  .yr-cancelbtn, .yr-submitbtn{
    min-width: 80px;
    width: auto;
  }
  .yr-cancelbtn.yr-btn-small span{
    padding: 8px 20px;
  }
  /* modal popup css */

  .red{
    color: red !important;
    margin-top: 10px;
    margin-left: 10px;
  }