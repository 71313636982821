.paymentForm .form-control{
    border-color: #aaa
}

.paymentForm .form-group label,
.paymentForm label{
    font-size: 14px;
    font-weight: 500;
    color: #666;
    margin-bottom: 4px;
}


.my-table td{
    width: 25%;
}

@media(max-width: 560px){
    .my-table td{
        min-width: 220px;
    }   
}