.FAQ_section span{
    margin-bottom: 10px;
}
.FAQ_section input{
    margin-top: 10px;
    max-height: 30px;
}
.FAQ_section{
    margin-bottom: 20px;
}

.addLink{
    margin-top: 7px;
    font-weight: 500;
    display: block;
    font-size: 12px;
    padding: initial;
    color: #087ab5 !important;
    cursor: pointer;
}
.conversationText{
    width: 80%;
    min-height: 300px;
    color: #495057 !important;
    font-weight: bolder;
    padding: 10px;
    line-height: 1.5;
}
.inputTextare{
    width: 100%;
    border: 1px solid #ebedf2;
    padding: 6px;
    min-height:20px
}