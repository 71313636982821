.bottom-tabs .nav-item {
  margin-right: 20px;
}

.nav-tabs .nav-link.below-link {
  padding: 12px 20px;
  border: none;
  border-bottom-width: 4px !important;
  font-size: 15.6px;
  transition: 0.4s ease;
  color: #087ab5 !important;
}

.nav-tabs .nav-link.below-link.active,
.nav-tabs .nav-link.below-link:hover {
  border: none;
  border-bottom: 4px solid #087ab5 !important;
}

.notify-via .field{
  margin: 0 !important;
}

.react-calendar{
  width: 100% !important;
  font-family: "Poppins", sans-serif !important;
}
#schedulenotification .ui.table tr td{
  padding: 10px !important;
}
.memberListSchedule{
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.alldaysschedule{
  display: flex;
  column-gap: 10px;
  margin-left: 10px;
  
}
.afterhour-ul{
  padding: 1px !important;
}