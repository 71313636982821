
.chat-header .ui.selection.dropdown {
    border: 0px solid #ebedf2;
    display: inline-block;
    width: auto;
    color: #087ab6;
}
.chat-header .ui.dropdown .menu>.item{
    white-space: nowrap;
    font-weight: 400;
    color: #087ab6;
}
.chat-header .ui.dropdown .menu{
    width: auto;
    font-weight: 400;
    right: 0;
    left: auto;
}
.chat-header .ui.dropdown .menu .selected.item, .chat-header .ui.dropdown.selected, .chat-header .ui.dropdown .menu>.item:hover {   
    background-image: linear-gradient(45deg, #087ab6 , #00d39e);
    color: #fff !important;
}

.mh-text-bold{
    font-weight: 600;
}