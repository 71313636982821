.analiytics-link > a {
    
    color: #4183c4 !important;
    text-decoration: none;
}
.ui.table tr td:first-child {
    text-align: left !important;
}
.ui.sortable.table thead th:first-child, .ui.table tr td:first-child {
    text-align: left !important;
}