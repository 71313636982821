div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
    display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    /* height: 31px; */
    /* margin: 0; */
    /* font-size: 12px; */
    width: 100%;
    border: 1px solid #eee;
    /* padding: 0 4px; */
    display: block;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    border-radius: 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 5px;
    cursor: move;
    border-radius: 8px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}
button.ReactTags__remove{
    margin-left: 5px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #589c15;
}
.yr-listbox2 {
    max-height: 338px;
    overflow-x: hidden;
    overflow-y: auto;
}
.yr-listbox2 li{
    background-color: #e3e3e3 !important;
    border-radius: 8px !important;
    padding: 10px;
    margin-bottom: 10px;
}
.yr-listbox2 li h5{
    margin:0 0 4px;
}

.yr-listbox2 li a{
    color: dodgerblue;
    font-size: 12px;
}

.yr-listbox2 li p{
    margin: 0 !important;
    font-size: 12px;
}

.yr-branchBtn2 i{
    color: #000 !important;
    font-size: 14px;
}

.showingDate{
    position:relative;
    display: block;
    color: dodgerblue;
}

.showingDate:after{
    content : '';
    position:absolute;
    width: calc(100% - 125px);
    height: 1px;
    background: #9d999999;
    top: 7px;
    transform: translateX(10px);
}

.yr-branchBtn2 {
    top: 50% !important;
    transform: translateY(-50%)
}
 ul.yr-listbox li .yr-branchBtn {
    position: absolute;
    top: 8px;
    right: 12px;
    color: #f56666;
    cursor: pointer;
}
 ul.yr-listbox li span i.fa-pencil {
    color: #488ebf;
    margin-right: 5px;
}