.yr-chat-popup {
    position: relative;
    z-index: 999999999999 !important;
    display: inline-block;
    top: 30px;
    left: 30px;
}
.yr-popupMsg {
    display: inline-block;
    position: relative;
    max-width: 270px;
    background-color: #fff;
    border-radius: .625rem;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.4);
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
span.yr-afterArrow {
    display: block;
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    right: -10px;
    bottom: 10px;
    background-color: inherit;
    transform: translateX(-6px) rotate(45deg);
    pointer-events: none;
    box-shadow: 2px -2px 2px 0 rgba(0,0,0,.2);
}
.yr-popupInnerMsg {
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    border-radius: inherit;
}

.yr-msgArea {
    padding: 14px;
    max-width: 100%;
    white-space: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.yr-msgArea span{
    box-sizing: border-box;
    max-width: 270px;
    font-size: 14px;
    white-space: normal;
    cursor: pointer;
    color: #333;
    font-family: Brandon,sans-serif;
}


.yr-closebublepop {
    color: #fff;
    display: inline-block;
    position: absolute;
    top: -10px;
    left: -10px;
    cursor: pointer;
    width: 18px;
    height: 18px;
    font-size: 12px;
    text-align: center;
    background: #1d1d1d;
    border-radius: 50px;
    border: 1px solid #fff;
}



.zy--closing__popup{
    position: absolute;
    left: 0;
    transform: translateX(-110%);
    bottom: 20px;
    max-width: 140px;
    width: max-content;
    min-height: 40px;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    filter: drop-shadow(0px 0px 6px #0004);
}

.zy--closing__popup:before {
    content: '';
    position: absolute;
    border: 7px solid transparent;
    border-left: 7px solid #fff;
    left: 100%;
    bottom: 10px;
}

.zy--closing__popup > span{
	position: absolute;
    left: -5px;
    top: -5px;
    background: #000;
    line-height: 1;
    padding: 1px 3px;
    color: #Fff;
    font-weight: 600;
    border-radius: 50%;
    cursor: pointer;
}

.zy--pp__txt{
	margin: 0 !important;
}

.txtEditorTab {
    border: 1px solid #0005;
    border-radius: 4px;
    overflow: hidden;
}

.txtEditorTab input:focus,
.txtEditorTab select:focus{
    outline:  none;
}

.txtEditorTab .tab--header{
    background-color: #eee;
    border-bottom: 1px solid #0005;
    padding: 10px;
    display: flex
    ;
}

.txtEditorTab .tab--header select{
    font-size: 14px;
    height: 25px;
    margin-right: 10px;
}

.txtEditorTab .tab--header input{
    height: 25px;
}

.txtEditorTab .tab--body input{
    width: 100%;
    padding: 15px 10px;
    border: none;
    font-size: 14px;
}



.radio-inputWrapper{
    display: flex;
    justify-content: space-between;
    max-width :300px;
}

.radio-inputWrapper label{
    margin-bottom: 2px;
}

.radio-inputWrapper label input{
    margin-right: 5px;
}

@media(min-width: 768px){
    .yr-preview.SMALL{
        max-width :280px;
    }

    .yr-preview.SMALL .yr-body-top{
        margin-bottom: 145px;
    }

    .yr-preview.SMALL header{
        height: 70px;
    }

    .yr-preview.SMALL .brand-left{
        height: 50px;
    }

    .yr-preview.SMALL .small-img-logo{
        width :30px;
        min-width: 30px;
        height: 30px;
    }

    .yr-preview.SMALL .yr-nav, 
    .yr-preview.SMALL .yr-close{
        font-size:12px;
    }

    .yr-preview.SMALL .yrbtn{
        padding: .2rem .4rem;
        font-size :10px;
        line-height: 20px;
        height :25px;
    }

    .yr-preview.SMALL .welcome-text{
        font-size: 10px
    }

    .yr-preview.SMALL .yr-chatlogo-mini{
        width: 20px;
        height :20px;
        min-width: 20px;
        min-height: 20px;
        line-height: unset;
    }

    .yr-preview.SMALL .small-img-logo img{
        width: 100%;
        height: 100%;
    }

    .yr-preview.SMALL .brand-name{
        font-size: 12px;
    }



    .yr-preview.MEDIUM{
        max-width :320px;
    }

    .yr-preview.MEDIUM .yr-body-top{
        margin-bottom: 195px;
    }

    .yr-preview.MEDIUM header{
        height: 95px;
    }

    .yr-preview.MEDIUM .brand-left{
        height: 60px;
    }

    .yr-preview.MEDIUM .small-img-logo{
        width :42px;
        min-width: 42px;
        height: 42px;
    }

    .yr-preview.MEDIUM .yr-nav, 
    .yr-preview.MEDIUM .yr-close{
        font-size:14px;
    }

    .yr-preview.MEDIUM .yrbtn{
        padding: .6rem;
        font-size :13px;
        line-height: 16px;
        height :32px;
    }

    .yr-preview.MEDIUM .welcome-text{
        font-size: 13.5px
    }

    .yr-preview.MEDIUM .yr-chatlogo-mini{
        width: 30px;
        height :30px;
        min-width: 30px;
        min-height: 30px;
        line-height: unset;
    }

    .yr-preview.MEDIUM .small-img-logo img{
        width: 100%;
        height: 100%;
    }

    .yr-preview.MEDIUM .brand-name{
        font-size: 15px;
    }

    .yr-loader{
        height: 0px;
        padding: 0px;
    }

.yr-preview.SMALL .small-img-logo button{
        width: 100%;
        background-color: rgb(0, 140, 197);
        height: 100%;
        background-size: 99%;
        background-position: center;
    }

}
[data-tooltip][data-inverted]:after {
    white-space: break-spaces;
    width: 200px;
    margin-left: 30px;
    margin-bottom: 5px;
}