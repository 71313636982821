/* General Modal Styling */
.custom-modal {
    border-radius: 10px !important;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2) !important;
    max-width: 600px !important; /* Adjust the maximum width */
    width: 90% !important; /* Responsive width for smaller screens */
    max-height: 200px !important; /* Limit height */
    position: fixed !important;
    top: 50% !important; /* Align vertically center */
    left: 50% !important; /* Align horizontally center */
    transform: translate(-50%, -50%) !important; /* Adjust for perfect centering */
    overflow: hidden !important; /* Ensure no unnecessary scrolling */
  }

  .custom-modal-layer{
    border-radius: 10px !important;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2) !important;
    max-width: 600px !important; /* Adjust the maximum width */
    width: 90% !important; /* Responsive width for smaller screens */
    max-height: 600px !important; /* Limit height */
    position: fixed !important;
    top: 50% !important; /* Align vertically center */
    left: 50% !important; /* Align horizontally center */
    transform: translate(-50%, -50%) !important; /* Adjust for perfect centering */
    overflow-x: hidden !important; /* Ensure no unnecessary scrolling */
  }

  
  /* Header Styling */
  .custom-modal-header {
    font-size: 1.8rem !important;
    padding: 1.25rem 0 0 1rem !important;
    color: #848588 !important;
    font-weight: 500 !important;
  }
  
  /* Content Styling */
  .custom-modal-content {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
    margin: 10px 0; /* Adjust spacing */
    padding: 0 !important;
  }
  
  /* Actions/Buttons Styling */
  .custom-modal-actions {
    display: flex !important;
    justify-content: right !important;
    padding: 1rem !important;
  }
  
  .custom-button {
    padding: 0.8rem 2rem !important;
    font-size: 1rem !important;
    font-weight: bold !important;
    border-radius: 5px !important;
  }
  
  .no-button {
    background-color: #e0e0e0 !important;
    color: #555 !important;
    margin-right: 10px !important;
    border: 1px solid #ccc !important;
    width:auto !important;
  }
  
  .no-button:hover {
    background-color: #d6d6d6 !important;
  }
  
  .yes-button {
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    width:auto !important
  }
  
  .yes-button:hover {
    background-color: #0056b3 !important;
  }

  .ui.form {
    font-size: 1rem !important;
  }


  