/*  Labels below slider */
.range-labels {
    padding: 0;
    list-style: none;

    li {
        position: relative;
        float: left;
        width: 50px;
        text-align: center;
        color: #b2b2b2;
        font-size: 10px;
        cursor: pointer;
        padding-bottom: 10px;


        label{
            margin-bottom: 0 !important;
            cursor: pointer;
        }

        div{
            border: 2px solid #000;
            height: 14px;
            border-top: 0;
            border-right: 0;
        }
    }
}
.disabled .rangeBox{
    background-color: grey;      
}
.rangeBox.selected{
    background-color: #1dc9b7;
}

.item.memberlistitem{
    border-radius: 5px;
    background-color: #e5e7eb;
    cursor: pointer;
    padding: 10px!important;
    margin-bottom: 10px;
    margin-right: 10px;
    /* border: 1px solid #b2b2b2; */
}
.item.memberlistitem:hover{
    background-color: #d1d5db;
}

.memberListSchedule label{
    margin-bottom: 0px;
    color: #000;
    font-weight: 400;
}
.memberListSchedule a{
    font-weight: 500;
}

.memberListBox{
    max-height: 200px;
    overflow-y: scroll;
    margin-top: 1opx;
    margin-bottom: 10px;
}
.schedule_extends{
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.showdateRangePicker{
    border-bottom: 1px solid #8f8f9d;
    cursor: pointer;
}
.notification_sent_via_times{
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 20px;
}
.notification_sent_via_times label{
    font-weight: 500;
}