.postcard--list li.postcard--listitem{
    margin:8px 5px;
    height: 220px
}
.postcard--list .postcard--listitem a{
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    transition: .4s ease;
}

.postcard--list li.postcard--listitem a img{
    width: 100%;
    height: 100%
}

.postcard--list li.postcard--listitem a:hover{
    box-shadow: 0 0 0 2px #087ab6;
    pointer-events: all;
}

div#seasonal_list > div.ui + div.calllistrule {
    border: none !important;
}

.click_false{
    pointer-events: inherit !important;
}
.click_false:after{
    z-index: 999;
}
.zindex-0{z-index: 0;} 
.modalcenter-box{
    pointer-events: inherit !important;
}

.hiddenvisibility {
    visibility: hidden;
    height:0px;
    overflow: hidden;
}


@media(max-width:1080px) and (min-width: 1000px){
    .postcard--list li.postcard--listitem{
        width:31.5%;
        height: auto;
    }
}


@media(max-width:768px){
    .postcard--list li.postcard--listitem{
        width:48%;
    }
}

@media(max-width:560px){
    .postcard--list li.postcard--listitem{
        width:100%;
    }
}

/* aslam added new css */
:root{
    --dark-color: #333;
    --grey-color: #808080;
    --light-color: #b3b1b1;
    --extra-light: #efefef;
    --white-color: #fff;
    --success-color: #00dd99;
    --green-light: #00ff99;
    --primary-color: #087ab6;
    

}


h1,h2,h3,h4,h5,h6{
    font-weight: 500;
}

ul{
    list-style: none;
    padding-left: 0;
}

select:focus{
    box-shadow: 0 0 0 1px  var(--primary-color) !important;
}


.text-blue{
    color: var(--primary-color);
}
.zyra--primary{
    background-color: var(--primary-color);
    color: #fff;
}

.zyra--btn.zyra--primary-gradient{
    background-image: linear-gradient(45deg, #087ab6 , #00d39e);
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}

.zyra--btn.zyra--primary-outline{
    background: #fff;
    color: var(--primary-color);
    border:1px solid var(--primary-color) !important;
    transition: .5s ease;
}


.zyra--btn.zyra--primary-outline:hover{
    background-color: var(--primary-color);
    color: #fff;
}

.zyra--btn{
    border: 0 !important;
    height: 38px;
    border-radius: 5px;
    white-space: nowrap;
    padding-left:1rem;
    padding-right:1rem;
}

.zyra--light{
    background-color: var(--extra-light);
}


.radius-30{
    border-radius: 30px;
}


.zyra-fs-14{
    font-size: 12px;
    opacity: .4;
}


.text--blue{
    color: var(--primary-color) !important;
}





/* post card form  css  */
.postCardImg{
    max-width: 300px;
    width: 100%;
    height: 200px;
    background-color: #efefef;
    border-radius: 5px;
    overflow: hidden;
}

.PostCard_Subtitle{
    font-size: 11px;
}