.archivechat_container {
    height: 265px;
    overflow-y: auto;
    padding: 0 0px !important;
    padding-left: 4px !important;
    /* cursor: pointer; */
}

.archivechat_container_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 10px;
    cursor: pointer;
}

.archivechat_container_item_1 {
    background-color: #f5f5f5;
    /* cursor: default !important; */
    width: 100%;
    height: 39px;
    /* padding-top: 10px;
    padding-left: 20px; */
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* cursor: pointer; */
}
.archivechat_item_description{
    display: flex;
    flex-direction: column;
}
.archivechat_item_avtar{
    background-color: #d7b144;
    padding: 6px;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
}
.archivechat_item_messagetime{

}
.archivechat_item_description_name{

}
.archivechat_item_description_number{

}

.archivechat_item_action{
    display: flex;
    flex-direction: row;
    column-gap: 6px;
}

.archivechat_item_action i {
    cursor: pointer;
    font-size: 12px;
}