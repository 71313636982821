.livechat_tab_container{
    padding: 10px 0px;
}

.livechat_sendatext{
    margin: 0px 20px;
}
.smschat__container{
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
}
.livechatformdiv{
    margin-bottom: 10px;
}
.livechatform_viewrecipient{
    cursor: pointer;
}
.livechatform_viewrecipient span{
    margin-left: 10px;
}

.liveChat__radiofield{
    display: flex;
    column-gap: 10px;
}
.livechat__checkterm{
    display: flex;
    align-items: center;
    column-gap: 6px; 
}
.livechat__formadd_cutom{
    display: flex;
    justify-content: flex-start;
    column-gap: 20px;
    align-items: center;
}
.livechat__addcustom_button{
    display: flex;
    column-gap: 10px;
}
.livechat__addcustom_button button{
    background-color: transparent;
    border: none;
}
.livechat__addcustom_form_input{
    margin-bottom: 10px !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0px !important;
    max-width: 250px;
}
.chatlist_number{
    max-width: 250px;
    width: 100%;
}
.chatlist_number .dropdown .yr-add-new{
    width: 100% !important;
}
.liveChat_formbutton{
    min-width: 110px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.chatlist_number button{
    width: 100% !important;
    border-bottom: 1px solid #dedede !important;
    background-color: #fff !important;
    box-shadow: none !important;
}

.urlshortner__box {
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
    padding: 10px;
    border-radius: 10px;
}
.csvupload_buttons{
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-top: 10px;
}
.view_recipient{
    display: flex;
    justify-content: space-between;
}
.viewRecipientBody .list_dataheading{
    border-bottom: 1px solid #000;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 8px;
}
.list_view{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.liveChat_formbutton_multiselect{
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
}

.viewRecipientBody{
    max-height: 350px;
    overflow-y: auto;
}
.livechat__addcustom_input_error{
    border-bottom: 1px solid red !important;
}