.ui.pagination.menu {
  float: right !important;
}
tfoot th {
  border: none;
}
.ui.celled.table tr td,
.ui.celled.table tr th {
  border-left: 0px !important;
}
.ui.table {
  border: 0px !important;
}

.ui.segment {
  box-shadow: none;
  border: 0;
  color: #646c9a;
}
.ui.table thead th,
.ui.table tfoot th {
  background: #fff;
}
.ui.table thead th {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
.ui.input {
  margin-left: 4px;
}
.ui.input > input {
  padding-left: 40px !important;
}
.ui.pagination.menu .active.item {
  background: #087ab5;
  color: #fff;
}
.pagination {
  border: 0 !important;
}
.ui.inline.dropdown {
  border-bottom: 1px solid #bebfc1;
  padding: 4px 14px;
}
.ui.sortable.table thead th,
.ui.table tr td {
  color: #6c7293;
  padding: 20px 10px !important;
}
.ui.sortable.table thead th:first-child,
.ui.table tr td:first-child {
  text-align: center;
}
.ui.sortable.table .btn.btn-icon {
  height: 1.5rem;
  width: 1.5rem;
}
.ui.sortable.table thead th:hover,
.ui.selectable.table tbody tr:hover {
  background: #fff !important;
}
