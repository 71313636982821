.yr-color-picker{
    width: 100%;
    display:flex;
    height:30px;
    background-color: #e6e7e8;
    align-items: center;
    margin-bottom: 10px;  
  }  
  /* .yr-color-picker .css-1yn2e29-InputColor{
    padding: 0;
    height:30px;
    width: 65%;
  } */
  .yr-color-picker span{
    padding-left: 10px;
    display: inline-block;
  }

  .yr-color-picker span:first-child{
    width:100%;
  }