.confusedbranch__heading{
    border: none;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 0px;
    padding-bottom: 25px !important;
}

.confusedbranch__heading p strong{
    color: #495057 !important;
}

.confusedbranch__content{
    padding: 20px;
}

.confusedbranch__content_menu{
    /* display: flex; */
    justify-content: space-between;
    flex-wrap: wrap;
}


.link_text{
    color: #6c7293 !important;
    font-weight: 500;
}
.confusedbranch__content_menu div{
    cursor: pointer;
}
.inputfield .is-invalid{
 border-color:red;
}
.errf{
    margin-left:20px;
    padding:5px;
}
.selectcss{
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: right;
}
.integrationlabels {
    line-height: 2;
    font-size: 16px !important;
    margin-left: 2%;
    margin-right: 2%;
    font-weight: 900  !important;
}
.clwidth{width: 93%;}
@media only screen and (max-width: 1160px) {
    .integrationlabels {
        line-height: 2;
        font-size: 16px !important;
        margin-left: 5%;
        margin-right: 2%;
        font-weight: 900  !important;
    }
}
@media only screen and (max-width: 1160px) {
    .integrationlabels {
        line-height: 2;
        font-size: 16px !important;
        margin-left: 15% !important;
        margin-right: 2%;
        font-weight: 900  !important;
    }
    .clwidth{width: 88% !important;}
}


