#teamMemberCallList .css-1jllj6i-control,
#teamMemberCallList .css-6y1x9t-control{
    min-height: 30px !important;
    margin:0;
}
#teamMemberCallList > div > button + div{
    margin-top: 0 !important;
}
#teamMemberCallList > div > button > span{
  color: #959393 !important;
}

#teamMemberCallList .css-48ayfv,
#teamMemberCallList .css-1r4vtzz{
    width: 250px !important;
    border-bottom:1px solid #ccc !important;
    border-top:0px solid #6c7293 !important;
    border-left:0px solid #6c7293 !important;
    border-right:0px solid #6c7293 !important;
    padding: 8px 8px 5px 8px !important;
    background-color: #FFF !important;
    border-width: 0 !important;
    border-bottom:1px solid #ccc !important;
    border-top:0px solid #6c7293 !important;
    border-left:0px solid #6c7293 !important;
    border-right:0px solid #6c7293 !important;
    box-shadow: 0 0px 0px 0px rgb(0 0 0 / 8%) !important;
    color: #959393 !important;
}

#teamMemberCallList .css-11unzgr [type="checkbox"]:before {
    position: relative;
    display: block;
    width: 11px;
    height: 11px;
    content: "";
}

#teamMemberCallList .css-11unzgr [type="checkbox"]:after {
  position: relative;
  display: block;
  /* border-radius:5px !important; */
  left: 0px;
  top: -16px !important;
  width: 9px;
  height: 9px;
  border-width: 1px;
  border-style: solid;
  border-color: #087ab6 #0595ae #0595ae #087ab6;
  content: "";
  background-color:#fff;
  background-repeat: no-repeat;
  background-position: center;
}

#teamMemberCallList .css-11unzgr [type="checkbox"]:checked:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAQAAABuW59YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAB2SURBVHjaAGkAlv8A3QDyAP0A/QD+Dam3W+kCAAD8APYAAgTVZaZCGwwA5wr0AvcA+Dh+7UX/x24AqK3Wg/8nt6w4/5q71wAAVP9g/7rTXf9n/+9N+AAAtpJa/zf/S//DhP8H/wAA4gzWj2P4lsf0JP0A/wADAHB0Ngka6UmKAAAAAElFTkSuQmCC'),
   linear-gradient(135deg, #02bfa4 0%, #00d29e 100%);
   color:#fff;
}

#teamMemberCallList .css-11unzgr [type="checkbox"]:disabled:after {
  -webkit-filter: opacity(0.4);
}
#teamMemberCallList .css-11unzgr [type="checkbox"]:before {
  width: 16px;
  height: 16px;
}
#teamMemberCallList .css-11unzgr [type="checkbox"]:after {
  top: -20px;
  width: 16px;
  height: 16px;
}


#teamMemberCallList .css-ik6y5r > div{
    max-height: 178px !important;
}

.dp--height{
    height:220px;
}

/* new css 23-03-22 */

.myTable2 th{
  background: #01d19f;
  padding: 15px 10px;
  color: #fff;
}

.myTable2 th, .myTable2 td{
border:1px solid;
}
.myTable2 th i{
/* width:25px; */
color: #fff;
/* font-size: 16px; */
}


.myTable2 th select{
border: none;
width:100%;
padding:5px 10px;
height:100%;
color: #fff;
display: inline-block;
background-color: transparent;
max-width: 150px;
}
.myTable2 th select option{
background-color: #fff;
color: #000;
}

.myTable2 th select option:disabled{
  background-color: #ccc;
}

.myTable2 th select:focus{
outline: none;
box-shadow: none !important;
}

.myTable2 th div{
display: flex;
align-items: center;
}

.myTable2 td{
background-color: #eafefc;
}


.contactVariableList .css-1qprcsu-option{
  pointer-events: none;
}
/* .myTable2  :first-child{
  -webkit-appearance: none !important
} */


.varListing{
  display: flex;
  flex-wrap: wrap;
  list-style: square;
  padding-left: 1.2rem;
}

.varListing li{
  width:47%;
  margin: 0 10px 10px 0;
  text-transform: capitalize;
}

.form-control.data-trigger-dropdown{
  padding: 10px 0  !important
}

.kt--checked.kt-radio.inalign > input ~ span{
  border-radius: 4px !important;
}
.kt--checked.kt-radio.inalign > input:checked ~ span{
  background-color: transparent;
}
.kt--checked.kt-radio.inalign > input:checked ~ span::after{
  background: transparent;
  border:none;
  border-radius: 0px !important;
  border-bottom: 2px solid #007ab5;
  border-left: 2px solid #007ab5;
  height: 8px;
  transform: rotate(-45deg);
}