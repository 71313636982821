.aboutus_lable span{
    font-size: 1rem;
}
.aboutus_lable{
    align-items: center;
}
.aboutus_skipbutton span{
    padding: 2px 8px !important;
}
.inputTextare{
    width: 100%;
    border: 1px solid #ebedf2;
    padding: 6px;
}
.skipbtn{
    background: #059eac !important;
    color: #fff;
}