.yr-icon-actcrm {
    width: 12px;
    height: 12px;
    margin-left: 4px;
    position: relative;
    display: inline-block;
}
.yr-icon-actcrm:after {
    content: '';
    background-image: url('../../media/logos/act_logo.png');
    background-size: contain;
    width:17px;
    height:17px;
    position: absolute;
    left: 0;
    top: 0px;
}

.required {
    font-size: 1rem;
}

.act--integration .table__wrapper{
    overflow-x: auto;
}

.my-act-checkbox{
    display: flex;
    justify-content: center;
}

.yr-icon-servicetitan {
    width: 12px;
    height: 12px;
    margin-left: 4px;
    position: relative;
    display: inline-block;
}
.yr-icon-servicetitan:after {
    content: '';
    background-image: url('../../media/logos/servicetitan.png');
    background-size: contain;
    width:17px;
    height:17px;
    position: absolute;
    left: 0;
    top: 0px;
}

#servicetitan .kt-input-icon{
    display: none;
}

#servicetitan .searchBox{
    border-bottom: none !important;
}