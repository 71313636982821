.sent-switch.kt-switch.chat-cus-check input ~ span:before{
    content: 'Sent';
    font-weight: 300;
    width:100px
}
.schedule-switch.kt-switch.chat-cus-check input ~ span:before{
    content: 'Schedule';
    font-weight: 300;
    padding-left: 10px;
    width:100px
}

.schedule-switch.kt-switch.chat-cus-check input ~ span:after{
    margin-left: 74px;
}

@media (max-width:991px) {
    .mn-120{
        min-width: 100px;
    }
}