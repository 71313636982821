.icon-btn-spl {
    padding: 2px;
    font-size: 30px;
    border: none;
    background: none;
}
.ui.checkbox .box:after, .ui.checkbox label:after {
    top: .5px !important;
}


.ui.checked.checkbox.datecheckox input:checked~.box:after, .ui.checked.checkbox.datecheckox input:checked~label:after {
    content: '\f00d' !important;
    font-family: FontAwesome;
    color:red !important;
}



.ui.checkbox.datecheckbox input:checked~.box:after, .ui.checkbox.datecheckbox input:checked~label:after {
    content: '\f00d' !important;
    font-family: FontAwesome;
    color:red !important;
}

.ui.checkbox.addresscheckbox input:checked~.box:after, .ui.checkbox.addresscheckbox input:checked~label:after {
    content: '\f111' !important;
    font-family: FontAwesome;
    color:green !important;
    line-height: normal;
}

.ui.checkbox.isaddress input:checked~.box:after, .ui.checkbox.isaddress input:checked~label:after {
    content: '\f111' !important;
    font-family: FontAwesome;
    color:green !important;
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 5% !important;
    padding: 3px;
}

button:disabled, button.disabled {
    background: none !important;
    color: #0006 !important;
    pointer-events: none;
    cursor: not-allowed;
    box-shadow: none;
}

.box-title {
    font-size: 1rem !important;
}
.ui.checkbox input[disabled]~.box:after, .ui.checkbox input[disabled]~label, .ui.disabled.checkbox .box:after, .ui.disabled.checkbox label {
    cursor: default !important;
    opacity: 1 !important;
}



.ui.checkbox .box:after, .ui.checkbox .box:before, .ui.checkbox label:after, .ui.checkbox label:before {
    display: flex !important;
    align-items: center;
    justify-content: center;
}


.icon-cog-spl{
    font-size: 15px !important;
    padding: 2px;
    border: none;
    background: none;
    margin-left: 2px;
    margin-top:5px !important;
}
