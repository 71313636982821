.footerbutton {
    display: flex !important;
    align-items: center;
}

.assistantsBox {
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* Space between dynamically added boxes */
    padding: 20px;
    /* background-color: #f8f9fa; */
    border-radius: 8px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
    /* Subtle shadow */
    margin-top: 10px;
    /* max-width: 800px; */
    /* Set a maximum width for aesthetics */
    /* margin-left: auto;
    margin-right: auto; */
    /* Center align */
    flex-wrap: wrap;    
}

 .assistantBox {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #dee2e6;    
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);     
    position: relative;
 } 

.assistantBox label {
    /* font-weight: bold; */
    margin-bottom: 5px;
}

.assistantBox input,
.assistantBox select {
    width: 100%;
    /* Full width for inputs */
    padding: 8px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
}

.assistantBox input:focus,
.assistantBox select:focus {
    border-color: #80bdff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
    outline: none;
}
.deleteButton{
    position: absolute;
    top: 0;
    right: 0;
}