.menuActiveDataconnection{
    color: #087ab5 !important;
    border-bottom: 1px solid #087ab5 !important;
}
.customModal{
    position: relative !important;
}

.closeButton{
    position: absolute;
    right: 14px;
    width: auto;
    top: 13px;
    background: #fd2727;
    border: none;
    color: #fff;
    padding: 5px 5px;
}
.closeButton i {
    margin:0px !important;
    color:#fff;
}
.btnAdj{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.button{
    width:auto !important
}

.btnrightAlligment{
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 1rem;
    text-align: center;
}
.iconstyle{
    vertical-align: middle !important;
    padding-right:0px;
}
.linkButton{
    background: none;
    font-size: 1.25rem !important;
    font-weight: 500 !important;
    border: none !important;
    padding: 0px;
    color: #0c72bf;
    font-weight: bold !important;
}
.linkButton i{
   margin-right: 4px !important;
}
/* ModalComponent.module.css */

.datalayerTable thead {
    border-radius: 9px 9px 0 0;
}

.datalayerTable thead tr th {
    background-color: #dbdbdb !important;
    color: #3e3e3e !important;
}

.datalaytd{
    border: solid 1px #ccc !important;
    border-left: solid 1px #ccc !important;
    padding:10px !important;
}
.datalaytd{
    border-left: solid 1px #ccc !important;
    padding:10px !important;
}
