.ui.table.cst-table tr th:nth-child(1),
.ui.table.cst-table tr td:nth-child(1){
    width: 20% !important;
}

.ui.table.cst-table:not(.table-col-fix) tr th:nth-child(2),
.ui.table.cst-table:not(.table-col-fix) tr td:nth-child(2){
    width: 30% !important;
}


.ui.table.cst-table tr th:last-child,
.ui.table.cst-table tr td:last-child{
    width: 150px !important;
    text-align: right;
}


.ui.table.cst-table-2 tr th:nth-child(1),
.ui.table.cst-table-2 tr td:nth-child(1){
    width: 20% !important;
}

.ui.table.cst-table-2 tr th:nth-child(2),
.ui.table.cst-table-2 tr td:nth-child(2){
    width: 20% !important;
}


.ui.table.cst-table-2 tr th:nth-child(3),
.ui.table.cst-table-2 tr td:nth-child(3){
    width: 20% !important;
}

.ui.table.cst-table-2 tr th:nth-child(4),
.ui.table.cst-table-2 tr td:nth-child(4){
    width: 20% !important;
}


.ui.table.cst-table-2 tr th:last-child,
.ui.table.cst-table-2 tr td:last-child{
    width: 150px !important;
    text-align: right;
}

.ui.table.cst-table tr td:last-child > a{
    margin-right: 1rem;
}
.ui.table.cst-table tr td:last-child > a + a{
    margin-right: 0;
    font-size:1.25em;
    position: relative;
    top: 1px;
}

.ui.table.cst-table tr:last-child td{
    border-bottom: 1px solid rgba(34,36,38,.1) !important;
}



.model-select-dropdown{   
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid hsl(227, 6%, 68%) !important;
    padding-left: 0px !important;
    padding-bottom: 0px;
    padding-top: 0px;
}

.model-select-dropdown:focus{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: var(--primary-color);
    outline: none;
    box-shadow: none;
}

.new-modal-body input,
.new-modal-body select,
.new-modal-body span{
    color: #444 !important
}


.new-modal-body input,
.new-modal-body select{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.new-modal-body select{
    background: none;
    height: auto;
}

.new-modal-body .data--row{
    margin-bottom: 1.5rem;
}
.new-modal-body .data--row select{
    padding:.65rem 1rem !important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 1rem center/8px 10px;
    margin-bottom: 12px;
}

.new-modal-body .data--row label{
    color: #444;
    margin-bottom: 4px;
    font-size:11px;
    font-weight: 500;
}


@media(max-width: 567px){
    .new-modal-body .data--row{
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #ccc;
    }
}



.err--red{
    color: red;
    font-weight: 500;
    font-size: 10.4px;
    
}


.alert--data__row{
    position: relative;
    justify-content:space-between;
    align-items:center;
    flex-wrap: wrap;
}

.alert--row__delete{
    position: absolute;
    top: 32px;
    right: -20px;
}

@media(max-width: 567px){
    .alert--row__delete{
        left: 50%;
        transform: translateX(-50%);
        top: unset;
        bottom: 10px;
        right: unset;
    }

    .alert--data__row{
        padding-bottom: 2.5rem !important;
    }
}

.no--input__allowed{
    cursor: not-allowed;
}

.no--select__bg{
    background-color: transparent !important;
}