.yr-videoBtn a {
  color: #67809f;
  text-decoration: none;
}
.yr-videoBtn img {
  width: 30px;
  margin-left: 10px;
}
.you-tube {
  width: 100%;
  height: -webkit-fill-available;
}
.yr-videoBtn {
  display: inline-block;
}
