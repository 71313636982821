.detail-box > div{
    max-width: 360px;
    width:100%;
    padding:1.5rem;
    border: 1px solid #3333;
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: auto;
    position: relative;

}

.detail-box > div span{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-right: 1rem;
    line-height: 1.5;
    position: absolute;
    background-image: linear-gradient(to right, #087ab6, #00d39e);
    color: #fff;
    top: -15px;
    left: 15px;
    border-radius:20px;
    padding: 4px 16px;
}
.detail-box ul{
    width: 100%;
    margin: 0;
}
.detail-box ul li{
    display: flex;
    align-items: center;
    margin: 10px 0

}

.detail-box ul li h4{
    width: 60%;
    margin: 0;
    font-size: 1.25rem;
}

 .detail-box ul li div{
    font-size: 1.25rem;
    width: 40%;
}


/* 
#monthlyUsage .table tr th:first-child{
    width: 80px ;
} */