#drop-container {
  background: #ffffff;
  min-height: 200px;
  padding: 10px;

  max-width: 100%;
  overflow: auto;
}
#drop-container input {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.img-preview {
  margin: 20px;
  width: 150px;
  height: 150px;
  display: inline-block;
  float: left;
}
.yr-createTicket {
  padding: 20px;
}
.dropzone {
  margin-top: 0;
  border: 2px dashed #54c6dd;
  position: relative;
}
.dropzone .dz-message {
  margin: 1em 0;
}
#drop-container {
  min-height: 80px;
}
.form-group label {
  margin-top: 0;
}
.yr-body-p {
  width: 100%;
}
@media (min-width: 1200px) {
  .margin-l-d-20 {
    margin-left: 20px;
  }
}
@media (max-width: 1200px) {
}
.yr-createTicket {
  padding: 20px 20px 30px;
}

.yr-createTicket .was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #fd2727 !important;
}

.ui.loading.form {
  position: fixed;
  cursor: default;
  pointer-events: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  background: #00000021;
}
.ui.loading.form:before {
  position: absolute;
  content: "Loading...";
  top: calc(50% - -26px);
  left: calc(50% - 30px);
  width: 60px;
  height: 28px;
  background: transparent;
  z-index: 100;
}
.ui.loading.form:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 3em;
  height: 3em;
  animation: form-spin 0.6s linear;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #767676 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0 0 0 1px transparent;
  visibility: visible;
  z-index: 101;
}
.yr-createTicket #help-error {
  font-size: 1rem;
}
