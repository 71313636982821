.yr-shadow {
  box-shadow: 0 0.7rem 0.6rem rgba(0, 0, 0, 0.1);
  margin-bottom: 45px;
}
.ui.star.rating .active.icon {
  color: #f36919 !important;
  text-shadow: none !important;
}
.conect-header {
  font-size: 1.55rem;
  line-height: 3rem;
}
.review-des {
  color: #aaa;
  font-size: 1.3rem;
}
.rep-details {
  font-size: 1.25rem;
  font-weight: 400;
}
.con-highlight {
  font-weight: 600;
}
.green {
  color: #01c7a1;
}
.ui.image {
  width: 100%;
}
.ui.image img {
  margin: auto;
}
@media (min-width: 1024px) {
  .yr-shadow {
    min-height: 232px;
  }
}
@media (min-width: 1400px) {
  .conect-header {
    font-size: 1.7rem;
  }
}
.star_custom_crs {
  font-size: xx-large;
  width: 22px;
  display: inline-block;
  color: gray;
  margin-right: 5px;
}
.star_custom_crs:last-child {
  margin-right: 0;
}
.star_custom_crs:before {
  content: "\2605";
}
.star_custom_crs.on {
  color: #f36919;
}
.star_custom_crs.half:after {
  content: "\2605";
  color: #f36919;
  position: absolute;
  margin-left: 0px;
  width: 15px;
  overflow: hidden;
  margin-top: -18px;
}
