.confusedbranch .ui.celled.table {
    table-layout: fixed;
}
  
.ui.table td, .ui.table th {
    vertical-align: middle; /* Align content vertically in the middle */
}

.table-cell {
    display: flex;
    align-items: center;
    justify-content: center; /* Optional: Center content horizontally */
    height: 100%; /* Ensures consistent height across rows */
}

.equal.width.fields textarea {
    margin: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    padding: .78571429em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    outline: 0;
    color: rgba(0, 0, 0, .87);
    border-radius: .28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color .1s ease, border-color .1s ease;
    font-size: 1em;
    line-height: 1.2857;
    resize: vertical;
    width: 100%;
}

.ui.tiny.modal{width: 600px;} 

.equal.width.fields button.btn.btn-icon {
    background-image: linear-gradient(45deg, #087ab6, #00d39e);
    box-shadow: 0px 4px 8px #b3b3b3;
    border: 0 !important;
    min-width: 100px;
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
    min-height: 38px;
    font-size: 15px;
    transform: 1s;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 5px ;
}

.ui.tiny.modal button.ui.icon.button {
    z-index: 9999999;
    position: absolute;
    right: -3px;
    top: 1px;
    background-image: linear-gradient(45deg, #03bfa4, #0797a9);
    padding: 2px;
    border: 3px solid;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: #fff;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
button.ui.icon.button i {
    font-size: 10px;
    color: #fff;
    height: auto;
}

.modal .ui.form input[type=text], .modal .ui.input>input {
    border-bottom: 0px solid hsl(227, 6%, 68%) !important;
}