.wsab-flex{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    height: auto;
    margin: 20px 8px;
  }
  .alert.workspace-note{
    border: 1px solid #ddd;
    /* height: 50px; */
    border-radius: 6px;
    background-color: #ffe0e2;
    color: #ff2660;
    font-weight: 500;
    line-height: 22px;
  }
  .workspace-automation .form-group label{
    color: #495057;
  }
  .workspace-automation .bold{
    opacity: 0.8;
  }
  .workspace-automation .yr-chat-round-box{
    padding: 20px 20px;
  }
  .move-btn{
    color: #495057 !important;
    opacity: 0.8;
  }
  .yr-chat-round-box.inner-box{
    margin-top: 20px;
    background-color:#ebfcff;
    padding: 8px 22px;
    margin-bottom: 10px;
  }
  .box-head{
    display: flex !important;
    flex-flow: row wrap;
    justify-content: space-between;
    height: auto;
    align-items: center;
  }
  .small-title{
    font-size: 14px;
    font-weight: 500;
  }
  .body-text{
    font-weight: 400;
  }
  .pre-number-box{
    font-size: 14px;
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }
  .yr-round-btn{
    background-image: linear-gradient(45deg, #087ab6 , #00d39e) !important;
    border: 0 !important;
    height: 32px;    
    color: #fff;
    border-radius: 2rem;
    white-space: nowrap;
    margin-bottom: 10px;
    padding: 0.25rem 1.2rem;
    font-size: 12px;
  }
  .yr-round-btn:hover{
    color: #fff;
  }
  .add-branch-title{  
    font-weight: 400;
    font-size: 22px;
    color: #929497;
  }
  .welcome-branch{
    border: 1px solid #cacaca;
    height: 30px;
    display: block;
    line-height: 30px;
    padding: 0 10px;
    /* border-bottom:0; */
    color: #929497 !important;
    font-weight:500 !important;
  }
  .workspace-automation .note-editor {
    border-radius: 0 0 4px;
    border: 1px solid #ddd;
  }
  
  .add-link {
    margin-top: 7px;
    font-weight: 500;
    display: block;
    font-size: 12px;
    padding: initial;
    color: #087ab5 !important;
    cursor: pointer;
  }
  .workspace-automation .input-group-text, .workspace-automation .form-control.br-bottom {
    background-color: transparent;
    border:0;
    border-bottom: 1px solid #ebedf2;
  }
  
  .form-control.br-bottom::-webkit-input-placeholder {
    color: #535557;
  }
  .form-control.br-bottom:-moz-placeholder {
    color: #535557;
  }
  .form-control.br-bottom::-moz-placeholder {
    color: #535557;
  }
  .form-control.br-bottom::placeholder {
    color: #535557;
  }
  .form-control.br-bottom:-ms-input-placeholder {
    color: #535557;
  }
  .ck.ck-editor__editable_inline{
    min-height: 130px;
  }
  .mrbottompadd{
    margin-bottom:10px;
  }
  .mrbottompadd strong{
    font-weight:400;
    font-size: 1.12rem;
    color: #495057;
  }
  .yr-round-btn{

   
    White-space: initial;
    

  }
  .text-align-right{
    text-align:right;
    padding-right:0px;
  }
  .mar-top-0{
    margin-top:0px !important;
  }
  .inline-block{
    display:inline-block;
  }
  select.inline-block {
    width:250px;
    margin-left: 20px;
  }
  .addbutton .ui.secondary.menu .item{
    font-size:14px !important;
    margin-bottom: 8px;
  }

  .addbutton .yr-automation-uodate .ui.pointing.secondary.menu {
    border-bottom: transparent;
    font-size: 1.2em;
    min-height: 47px;
    flex-wrap: wrap;   
}
.addbutton .ui.secondary.pointing.menu .item {
  padding: .8em !important;
      padding: .5em !important; 
  }
  .addbutton  .ui.pointing.secondary.menu {
    flex-wrap: wrap; 
}
  .mt-10{
    margin-top:10px;
  }
  .mt-20{
    margin-top:20px;
  }
  .mt-40{
    margin-top:40px;
  }
  .reviewselect{
    width:80%;
  }
  .addButtonDiv .ui.segment{
       overflow:inherit;
  }
  .workSpaceAddField .chatCompleted  .kt-checkbox > span{
    left:0px;
  }
  .addEditBranch{
    max-width: 100%;
  }
  .textleft{
    text-align: left;
  }
  .textright{
    text-align: right;
  }

  .relative{
    position:relative;
  }
  .choicedelete{
    position: absolute;
    right:-17px;
    top:14px;
  }
  .rightzero{
    right:0px !important;
  }
  .rightfive{
    right:-5px !important;
  }
  .timezonedata-table {
    overflow: auto;
  }
  .table.timezonetable{
    min-width: 750px;
  }
  .table.timezonetable tbody td:first-child{
    width: auto !important;
  }
  .livechatrule.modal .formadata .form-group{
    margin-bottom: 1rem !important;
  }
  .rdw-link-modal{
     height:auto !important;
  }
  .rdw-embedded-modal-size,.rdw-image-modal-size {
    display:none !important;
  }
  .viewlivelinks{
    vertical-align: middle; 
   
    margin-left: 23px;
    vertical-align: top;
    margin-top: 11px;
   
    display: inline-block;
  }
  .breakword{
    word-break: break-all;
  }
  .question_map_image img{
    max-width:100%;
  }


/* test view */
/*************************/
.yr-testChat {
  position: fixed;
    bottom: 0;
    right: 0;
    background: #ebecf4;
    padding: 0;
    height: calc(100vh - 25%);
    width: 360px;
    display: block;
    z-index: 9999999999;
}

.yr-testHead {
  background: #d3d4de;
  background-image: linear-gradient(to right, #087ab6 , #00d39e);
}

.yr-testHead ul {
  display: flex;
  list-style: none;
  padding: 10px 15px;
  margin: 0;
}

.yr-testHead ul li {
  flex-grow: 1;
  color: #fff;
}
.yr-testHead ul li a {
  color: #fff;
  line-height: 24px;
}
.yr-textFooter {
  background: #ccc;
}

.yr-conTentArea {
  overflow: auto;
  padding-bottom: 0;
  background: #f4f4ff;
  padding-top: 10px;
  position: relative;
  height: calc(100% - 80px);
}

.yr-textFooter input {
  width: 100%;
  padding: 8px;
  border: 2px solid #ccc;
}

.yr-title {
  font-size: 16px;
  font-weight: 600;
}

.yr-testChat .yr-TextArea {
  padding: 5px 25px 5px 15px;
}

.yr-textSec {
  border-left: 4px solid #3598dc;
  padding-left: 8px;
}

.mapiconRight {
  position: relative;
}

.yr-faMapIcon {
  position: absolute;
  right: 12px;
  top: 6px;
  font-size: 18px;
  color: #217fbf;
}

.eyeiconRight {
  position: relative;
  width: 80%;
}
.yr-faEyeIcon {
  position: absolute;
  right: 0px;
  top: 6px;
  font-size: 18px;
  color: #217fbf;
}

.yr-TextBox {
  padding: 15px 8px;
  background: #efefef;
  border-radius: 8px !important;
  margin: 15px 15px;
  color:#464545;
}

.yr-texttitle {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 13px;
  color:#464545;
}

.yr-TextBox select {
  padding: 5px;
  background: transparent;
  border: 0px;
  border-bottom: 1px solid #cecbcb;
  margin-bottom: 10px;
  outline: 0;
  width: 100%;
  color: #807e7e;
}

.yr-TextBox p {
  font-weight: 600;
  font-size: 12px;
  margin: 0;
} 

.response_time_display{
max-width: 200px;
display: inline-block;
margin-left: 10px;
}
#successAddSuggestedExamples{
z-index: 9999999999999;
} 

.yr-checkboxnewdesign {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #f1f3f3;
  border-radius: 25px;

}

.yr-checkBtn {
  display: inline-flex;
  padding: 0px 20px;
}

.yr-checkBtn .mt-checkbox {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-bottom: 8px;
  font-weight: 300;
  
}
.yr-checkBtn .mt-checkbox input {
  margin: 0;
  position: relative;
  top: 1px;
  margin-right: 5px;
}

.yr-checkboxnewdesign .yr-checkBtn .mt-checkbox{
  padding:0px 0px 0px 10px;
  border:none;
}
/* test view */
  
.botbuilder-editor-class figure{
  margin:0;
}
.botbuilder-editor-class .rdw-image-imagewrapper img{
  max-width: 100%;
}
.editorexist img{
  max-width:100%
}
.cplt_chat img{
  max-width:250px
}

/* new bot builder */
.morebranchlink input::-webkit-outer-spin-button,
.morebranchlink input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.morebranchlink input[type=number] {
  -moz-appearance: textfield;
}
/* end new bot builder */
.gm-style .gm-style-iw-c{
  top:0px !important
}
.gm-style .gm-style-iw-t::after{
  top:0px !important 
}

.kt-page--fluid .kt-container.botbuilderdetailbody {
  width:100% !important;
}
.inlineelement{
  display:inline
}
.pd10botbuilder{
  padding-top:10px
}

/* add dated */
.branchlistbuilder .box-head{
  position: relative;
} 
/* .branchlistbuilder .right-action-btns{
  position: absolute;
  right:0;
  top:0;
} */

.branchlistbuilder .btn.btn-icon{
  height: 1rem;
  width: 1rem;
  margin: 0 .25rem;
}
.branchlistbuilder .small-title {
  font-size: 14px;
  font-weight: 500;
  /* max-width: 150px; */
  position: relative;
  /* display: flex;  */
}

.branchlistbuilder .btn-clean.btn-move{
  padding-left: .5rem;
  padding-right: .5rem;
}

.branchlistbuilder select.direction,
.branchlistbuilder select.branchcount{
    padding: 0.65rem .15rem .65rem .25rem !important;
   
}

.branchlistbuilder .small-title > span.box-head-title{ 
  margin-top:-3px;
}
.branchlistbuilder .yr-sticky-header{
  z-index:1
}
.botbuilderdetailbody .la-trash{
  color:red !important
}
.botbuilderdetailbody .la-clone{
  color:red !important
}
.botbuilderdetailbody .la-edit{
  color:#1aadfb !important
}
.botbuilderdetailbody.kt-container {
  margin:0px !important;
  width: 100% !important
}

.botbuilderdetailbody .btn-clean{ padding-left:4px;padding-right:4px }

.rightbotbuilder .d-inline-block{
  margin-left:0px !important;
  display: flex !important;
}
.chat-cus-check.acceptattachemnt{
  vertical-align: middle;
  margin-left: 20px;;
}
.transscript_rule{
  vertical-align: middle;
  margin-right:10px
}
.textrule{
  margin-top:10px;
  margin-left:22px;
}
.review_tb_content{
  vertical-align: top;
}
/* add dated */
  @media (max-width: 1299px) {
    .wsab-flex{        
        height: auto;
        margin: 20px 8px;
        width: 100%;
    }
  }
  @media (min-width: 1300px) {
    .wsab-flex{width: 80%; margin: 0 auto; margin-left: 20%;}
    .right-btns, .workspace-note{
      margin-top: -65px;
      margin-bottom: 0;
    }
    .botbuilderdetails .right-btns, .botbuilderdetails .workspace-note {
      margin-top: 15px;
      margin-bottom: 0;
  }
  
  }

    @media (max-width: 768px){
    .mob-align-left{
        text-align:left;
        margin-top:10px;
    }
    }

    .inner-wrapper{
      display: inline-flex;
      width: auto;
    }
    @media (min-width: 1024px) and (max-width:1300px){
      .box-head{
      display:unset !important;
      }
    
      .right-action-btns{
      position:relative !important;
      margin:.5rem 0 0;
      }

      .inner-wrapper{
        width: 100%;
        justify-content: flex-end;
        display: flex;
      }

      .inner-wrapper > select{
        max-width: 70px;
        font-size: 12px;
      }
      .rightbotbuilder .d-inline-block{
        margin-left:0px !important;
      }
      
    }
  
    .completedchatmaintestingbot.kt-container{
      margin: 0 !important;
    }
    
    .showCountryCode span.countrycodeshow{
      vertical-align: top;
      margin-right: 12px;
      display: inline-block;
      margin-top: 8px;
    }

   /* .completedchats .ui.segment + div + div.ui.pagination.menu{
      margin-right: 1vw;
    } */


    .editorImgHidden .rdw-image-wrapper{
      display: none;
    }

    [data-tooltip]:not([data-position]):before {
      left: 50%;
  }
  [data-tooltip]:hover:after, [data-tooltip][data-position="bottom center"]:hover:after {
    transform: translateX(-20%) scale(1)!important;
}

.llm-generated-background{
  background-color: #686868;
}