.popup-content{    
    color: #848588;
    border-bottom: 1px solid #ddd !important;
    margin: 20px;
}
.search-btn{
    color:#087ab5;
}
.yr-datatable{
    margin: 25px auto !important;   
}

.ui[class*="very basic"].table:not(.sortable):not(.striped) thead tr:first-child th{
    color: #848588;
    font-weight: 400;
}

/* .ui.table:not(.unstackable) tbody, .ui.table:not(.unstackable) tr, .ui.table:not(.unstackable) tr > td, .ui.table:not(.unstackable) tr > th {
    width: 35% !important;
    display: revert !important;
  }

  .ui.sortable.table thead th:first-child, .ui.table tr td:first-child {
    width: 30% !important;
    text-align: left;
} */

.ui.sortable.table thead th:first-child, .ui.table tr td:third-child {
    text-align: center;
}

.mh-qt-Action{
    text-align:center !important;
    width: 10% !important;
}


.yr-right-footer {
    padding-right: 20px;
    position: relative;
    /* bottom: -14px; */
}
.yr-right-footer .ui.selection.dropdown {
    border: 0px solid #ebedf2;
    display: inline-block;
    width: auto;
    color: #087ab6;
}
.yr-right-footer .ui.dropdown .menu>.item{
    white-space: nowrap;
    font-weight: 400;
    color: #087ab6;
}
.yr-right-footer .ui.dropdown .menu{
    width: auto;
    font-weight: 400;
    right: 0;
    left: auto;
}
.yr-right-footer .ui.dropdown .menu .selected.item, .yr-right-footer .ui.dropdown.selected, .yr-right-footer .ui.dropdown .menu>.item:hover {   
    background-image: linear-gradient(45deg, #087ab6 , #00d39e);
    color: #fff !important;
}

.ui.selection.active.dropdown .menu{
    border-bottom-width: 1px !important 
}

.btn-sm i {
    padding-top: 5px;
}

.ui.inline.dropdown .menu{
    top:-138px;
}

.selection .divider{
    min-width:100px !important;
}
#liveChatPage .reply-box{
margin-right: auto;
}
.kt-portlet__foot.right-footer{
    width: 97%;
}
#liveChatPage .yr-search input{
    border-bottom: 0px !important;
    padding: 0px;
    }
    .yr-modalDatatable thead tr:first-child>th:first-child, .yr-modalDatatable tbody tr td:first-child {
        padding-left: 0px !important;
        text-align: left !important;
        width: 33px !important;
    }
    .yr-modalDatatable thead tr:first-child>th:last-child, .yr-modalDatatable tbody tr td:last-child {
        width: 70px !important;
        text-align: right;
    }
    .textEllip{ white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 90px !important;
        display: block;
    }

    .textEllipDesc{ white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 330px !important;
        display: block;
    }

    .mh-chatImg {
        width: 40px !important;
        height: 40px !important;
    }
@media (max-width: 768px){
    #liveChatPage .css-13azwyo.kt-portlet__body.mh-oneChatContainer {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    #liveChatPage .kt-widget3 {
        padding-right: 10px;
    }
    .kt-portlet__foot.right-footer{
        width: 93%;
    }
    .css-1qyrjaz, [data-css-1qyrjaz]{
        bottom: 0px;
        right: 15px;
    }
    #liveChatPage .ui.table:not(.unstackable) tbody, #liveChatPage .ui.table:not(.unstackable) tr, #liveChatPage .ui.table:not(.unstackable) tr>td, #liveChatPage .ui.table:not(.unstackable) tr>th{
        display: revert !important;
        padding-top: 10px !important;
        padding-left: 0px !important;
    }
    #liveChatPage .ui.pagination.menu {
        float:none  !important;
        padding-top: 10px;
    }
    .mh-joinIntotheConversation{
        width: 70% !important;
    }
    
    }


    #QuickTextTable .center{
        text-align: center !important;
    }