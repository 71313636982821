.zy-chatStats{
    display: flex;
    border-radius: 10px;
    box-shadow: 0 5px 20px -4px #0004;
    border:1px solid #0002;
    align-items: center;
}

.chat__dashboard h2{
    color: #000;
    font-weight: bold;
}

.chat__dashboard h2.mt-31{
    margin-top: 2.2rem;
}
.chat__dashboard h2 span{
    color: #999;
    font-weight: 300;
    display: block;
}

.zy-chatStats li:nth-child(2){
    background-color: #0001;
}
.zy-chatStats li{
    width: calc(100% / 3);
    padding: 30px 20px;
    text-align: center;
}
.zy-chatStats label{
    font-weight: 500;
    color: #000;
    margin-bottom: 0;
    font-size: 1.5rem;
}
.zy-chatStats h4{
    font-size: 4.5rem;
    margin: 0;
    color: #000;
}

.zy-clickCounts{
    border-radius: 10px;
    box-shadow: 0 5px 20px -4px #0004;
    max-height: 463px;
    overflow: auto;
}
.zy-clickCounts::-webkit-scrollbar {
    width: 5px;
    }
    
    /* .zy-clickCounts::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    } */

.zy-clickCounts li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.zy-clickCounts li:not(:last-child){
    border-bottom:1px solid #0002;
}

.zy-clickCounts li div {
    display: flex;
    justify-content: space-between;
    width: 60px;
}





.date--picker{
    font-family:'Poppins', sans-serif;
    letter-spacing: 1px;
}


.popularUrlList {
    margin-top: 24px;
}
.popularUrlList li:nth-child(odd){
    background-color: #efefef;
}
.popularUrlList li{
    border-radius: 4px;
    padding:8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

}
.popularUrlList li strong{
    font-size: 14px;
    font-weight: bold;
}
.zy-clickCounts li b{
    font-size: 14px;
    font-weight: bold;
}
