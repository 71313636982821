.modal-video-movie-wrap iframe{
    background-color: white;
    padding:15px;
    border-radius: 10px;
}
.modal-video-movie-wrap{
    background:none;
}

.modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -22px;
    right: -21px;
    display: inline-block;
    width: 24px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
}

#tranScriptChatList .ui.table:not(.unstackable) tbody,
#tranScriptChatList .ui.table:not(.unstackable) tr,
#tranScriptChatList .ui.table:not(.unstackable) tr > td,
#tranScriptChatList .ui.table:not(.unstackable) tr > th {
  width: auto !important;
  display: revert !important;
}
.notes img {
    width:50px;
    height:50px;
    border-radius:50px !important;
    vertical-align: top;
    display:inline-block;
}
.notesDesctiption{
    display:inline-block;
}
.deleteIcon{
    font-size:14px;
    cursor: pointer;
}
.notes{
    padding:10px 0px;
}
.companyNameDate .name{
    font-size: 14px;
    font-weight: 500;
}
