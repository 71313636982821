    /* manage contact 2 */
    #mh-CallList .yr-details-analy .table tbody td:first-child {
        min-width: auto;
    }
    #mh-CallList .yr-details-analy tbody td:first-child{
    width: 40%
    }
    #mh-CallList .yr-selectBtnSec{
        display: flex;
        flex-wrap: wrap;
        }
        #mh-CallList .yr-gradientBorderbtn{
        background: linear-gradient(90deg, #00d39e , #087ab6);
        box-shadow: 0px 4px 8px #b3b3b3 !important;
        border-radius: 20px;
        color: #333;
        display: inline-block;
        font-size: 16px;
        padding: 3px;
        text-decoration: none;
        min-width: 130px;
        border: 0;
    }
    #mh-CallList .yr-details-analy .yr-gradientBorderbtn select{
        border-radius: 20px;
    }
    #mh-CallList .yr-contactengagement ul {
        display: flex;
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
    }
    
    #mh-CallList .yr-contactengagement ul li {
        flex: 1;
        border: 1px solid #d8d8d8;
        padding: 10px;
        text-align: center;
        border-left: 0;
        min-height: 264px;
        align-items: center;
        justify-content: center;
        display: inline-flex;
    }
    
    #mh-CallList  .yr-contactengagement ul li:first-child {
        border-left: 1px solid #d8d8d8;
    }
    #mh-CallList .yr-deliverrates tr,.yr-deliverrates tr td {
        border: 1px solid #d8d8d8;
    }
    #mh-CallList .yr-config-setting tbody tr td i {
        font-size: 18px;
    }
    #mh-CallList .yr-dr-box h3 {
        font-size: 25px;
        font-weight: 600;
        line-height: 25px;
    }
    #mh-CallList .yr-deliverrates .yr-dr-box h3 {
        font-size: 20px;
    }
    #mh-CallList .yr-dr-box {
        text-align: center;
    }
    #mh-CallList .pd-15 {
        padding: 15px;
    }
    #mh-CallList  .yr-dr-box strong {
        font-weight: 600;
        margin-bottom: 8px;
        display: block;
    }
    #mh-CallList .yr-dr-box p {
        margin-bottom: 6px;
    }
    #mh-CallList .yr-dr-box a {
        color: #02bea3;
        text-decoration: underline;
    }
    #mh-CallList .yr-contactengagement>h3, .yr-deliverrates>h3 {
        margin-bottom: 20px;
        font-size: 16px;
        margin-top: 20px;
    }
    #mh-CallList .yr-deliverrates, .yr-contactengagement {
        margin-bottom: 40px;
    }
    #mh-CallList .yr-loader-area {
        padding: 15px;
    }
    #mh-CallList .info-icon:hover .clicktoshow{
        opacity: 1;
            visibility: visible;
        }
        #mh-CallList  .clicktoshow{
            opacity: 0;
            visibility: hidden;
        position:relative;
            transition: top 0.3s cubic-bezier(0.25, 0.1, 0.11, 1.29), left 0.3s cubic-bezier(0.25, 0.1, 0.11, 1.29), opacity 0.3s ease;
        }
        #mh-CallList   .clicktoshow{
            position: absolute;
            background: #343738;
            border-radius: 4px;
            box-shadow: 0 4px 12px rgba(0,0,0,0.2);
            padding: 10px 20px;
            -webkit-font-smoothing: antialiased;
            line-height: 1.4;
            font-size: 10px;
            color: #fff;
            word-break: break-word;
            text-align: center;
            display: block;
        }
        #mh-CallList  hr.loader {
        height: 2px;
        background: #02c1a3;
    }
    #mh-CallList  .yr-manage-contact-popup .yr-cancelbtn {
        font-size: 13px;
        min-width: 120px;
        height: 43px;
        box-shadow: 0px 4px 8px #b3b3b3 !important;
        }
        #mh-CallList  .yr-manage-contact-popup .form-group {
        margin-bottom: 1rem;
    }
    #mh-CallList   .yr-manage-contact-popup .dropdown-menu.inner > li:hover > a {
        background: #f7f8fa;
        background-image: linear-gradient(45deg, #087ab6 , #00d39e) !important;
    }
    #mh-CallList  .yr-manage-contact-popup .dropdown-menu.inner > li:hover > a .text, .yr-manage-contact-popup .dropdown-menu.inner > li:hover > a .bs-ok-default.check-mark {
        color: #ffffff;    
    }
    #mh-CallList  .yr-manage-contact-popup .dropdown-menu.inner > li > a .text{
        margin-left: 15px;
        margin-right: 0px;
    }
    #mh-CallList  .yr-manage-contact-popup .dropdown-menu {
        border: 1px solid #bbbbbb !important;
        padding: 0px;
    }
    #mh-CallList  .yr-manage-contact-popup .dropdown-menu .show {
        border: 0px!important;
    }
    #mh-CallList  .yr-manage-contact-popup span.bs-ok-default.check-mark {
        position: absolute;
        top: 16px;
        display: inherit !important;
        left: 10px;
    }
    #mh-CallList  .yr-manage-contact-popup .bootstrap-select .bs-ok-default:after {
        content: '';
        display: block;
        width: .5em;
        height: .5em;
        border-style: solid;
        border-width: 0 0.16em 0.16em 0;
        transform: rotate(-45deg);
    }
    #mh-CallList   h3.sm-b-title {
        font-weight: 700;
        font-size: 18px;
    }
    #mh-CallList table.yr-bucket-table td span {
        display: block;
        font-size: 11px;
    }
    #mh-CallList  table.yr-bucket-table td span.b {
        font-weight: 600;
    }
    #mh-CallList table.yr-bucket-table td span.t-sm{
        font-size: 13px;
    }
    #mh-CallList table.table.yr-bucket-table .btn {
        background: #e8e8e8;
        width: auto;
        padding: 0px 12px;
        height: 30px;
        line-height: 25px;
        margin-right: 10px;
    }
    table.table.yr-bucket-table .btn span {
        display: inline-block;
        border-left: 1px solid #f5f5f5;
        width: 5px;
        height: 27px;
        margin-left: 10px;
    }
    #mh-CallList .yr-ep-icon {
        display: inline-flex;
    }
    #mh-CallList  .yr-ep-icon a {
        margin-right: 10px;
        color: #b3b1b1;
    }
    #mh-CallList .yr-mc-select select {
        border: 0px;
        color: #149dd8 !important;
        font-size: 13px;
        font-weight: 500;
        padding-left: 0;
    }
    #mh-CallList  .yr-mc-select {
        display: inline-block;
        margin-right: 20px;
    }
    #mh-CallList  .hr-top {
        border-top: 2px solid #ddd;
        padding-top: 20px;
    }

    #mh-CallList  .yr-manageContacts-2 tr th{
        white-space: nowrap;
    }
    #mh-CallList  .yr-manageContacts-2 i.fa.fa-chevron-right.blue {
        margin-left: 8px;
    }
    #mh-CallList  .yr-nowrap{
        white-space: nowrap;
    }
    
    #mh-CallList   .yr-manageContacts-2 table.dataTable thead .sorting:before, .yr-manageContacts-2 table.dataTable thead .sorting_asc:before, .yr-manageContacts-2 table.dataTable thead .sorting_desc:before{
        bottom: 24px !important;
    }
    #mh-CallList  .yr-manageContacts-2 table.dataTable thead .sorting:after, .yr-manageContacts-2 table.dataTable thead .sorting_asc:after, .yr-manageContacts-2 table.dataTable thead .sorting_desc:after{
        bottom: 14px !important;
    }
    #mh-CallList .yr-manageContacts-2 .dataTables_wrapper .dataTable th:first-child, .yr-manageContacts-2 .table tbody tr td:first-child {
        width: 30px !important;
        min-width: auto !important;
    }
    #mh-CallList .yr-manageContacts-2 .table tbody tr td:first-child .kt-checkbox {
        width: 18px;
        height: 18px;
    }
    #mh-CallList .yr-manage-contact2 .btn-outline-info.zy-outline {
        color: #159bd5;
        border-color: #159bd5;
        border: 2px solid;
    }
    #mh-CallList .yr-manage-contact2 .dataTables_wrapper .dataTable th .kt-checkbox{
        margin-top: auto;
        margin-bottom: 14px;
    }
    #mh-CallList  .bck-btn a i {
        color: #1aadfb;
    }
    #mh-CallList  .bck-btn a {
        font-size: 16px;
        color: #8c8a8a;
    }
    #mh-CallList .yr-campaigns-model h4 {
        color: #1b8dca;
        font-size: 16px;
        margin-top: 30px;
    }
    #mh-CallList .new-model-popup .yr-campaigns-model .modal-header {
        border-bottom: 1px solid #ddd;
    }
    @media (max-width: 480px){
        #mh-CallList  .yr-ameplaybooks .nav-tabs.nav-tabs-line .nav-item {
        min-width: auto;
    }
    }
    #mh-CallList .yr-manage-contact2 h2, p,td,th{
        color:#959393 !important;
    }
    #mh-CallList .fa-caret-left:before {
        content: "\f0d9";
    }

    #modalContactViaID .modal-body{
        padding:0px;
    }

    #modalContactViaID.new-model-popup .yr-campaigns-model .modal-header{
        border-bottom: none;
    }

    #modalContactViaID .kt-radio > span{
        top:-10px !important;
    }

    #modalContactViaID .yr-cancelbtn span{
        padding: 10px 20px;
    }

    #AddEditContactModal .react-datepicker-wrapper{
        width:100%;
    }
    #mh-CallList .csvColumnFields{
        margin: 5px;
        background: #635e5e;
        padding: 4px;
        border-radius: 7px;
        color: white;
        display: inline-block;
    }

    #mh-CallList .mh-CSVbrowse{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    }

    #mh-CallList .mh-CSVbrowseError{
        color:red;
        margin:5px
    }
    #mh-CallList .yr-manage-contact2 .ui.table th.three.wide{
        width:8% !important;
    }

    #mh-CallList .yr-manageContacts-2 .kt-badge.kt-badge--pill {
        line-height: .1;
    }

  /* @Rohit*/
  #mh-CallList .form-control-notes {
        display: block;
        width: 98%;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    #mh-CallList .call-notes-red {
        color: red; cursor: pointer;
      }

  
      #mh-CallList .c-comment-list .media {

        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }

    #mh-CallList .call-notes-img  {  height: 50px; width: 50px; }
    #mh-CallList .call-notes-font {  font-size: 14px; font-family: "Poppins", sans-serif;  }
    #mh-CallList .media-heading   {  font-size: 14px; margin-bottom: 0px;  }
   /*.media-body { padding-top: 6px; }*/
   #mh-CallList .media-body > h4 {
            margin-top: 0px !important;
    } 

    #mh-CallList  .c-date { color: #959393; }
    #mh-CallList .tittle { color: #087ab6; }
    #mh-CallList .media-left {
        margin-right: 10px;
        width: 50px;
        height: 50px;
        border-radius: 50% !important;
        overflow: hidden;
    }

   /* Rohit Css */
    
   #mh-CallList .text-call-list:after {

            content: '';
            background: url(http://localhost/zyratalk/assets/plus-blue.svg);
            width: 4px;
            height: 5px;
            position: relative;
            padding: 8px;
            background-size: 9px;
            background-repeat: no-repeat;
            left: 8px;
            top: 13px;
        }
        #mh-CallList .textcenter{

        text-align: center;
    }
    #mh-CallList .pointer {cursor: pointer !important;}

    #mh-CallList .css-1r4vtzz {
      
        width: 100% !important;
        border-bottom:1px solid #ccc !important;
        border-top:0px solid #6c7293 !important;
        border-left:0px solid #6c7293 !important;
        border-right:0px solid #6c7293 !important;
        padding: 0px 0px 0px 0px !important;
        background-color: #FFF !important;
        border-width: 0 !important;
        border-bottom:1px solid #ccc !important;
        border-top:0px solid #6c7293 !important;
        border-left:0px solid #6c7293 !important;
        border-right:0px solid #6c7293 !important;
        box-shadow: 0 0px 0px 0px rgb(0 0 0 / 8%)  !important;
        color: #959393 !important;

    }
    #mh-CallList .css-48ayfv {

        width: 100% !important;
        border-bottom:1px solid #ccc !important;
        border-top:0px solid #6c7293 !important;
        border-left:0px solid #6c7293 !important;
        border-right:0px solid #6c7293 !important;
        padding: 8px 8px 5px 8px !important;
        background-color: #FFF !important;
        border-width: 0 !important;
        border-bottom:1px solid #ccc !important;
        border-top:0px solid #6c7293 !important;
        border-left:0px solid #6c7293 !important;
        border-right:0px solid #6c7293 !important;
        box-shadow: 0 0px 0px 0px rgb(0 0 0 / 8%) !important;
        color: #959393 !important;
    }
    #mh-CallList  .mat-style-call-list {
         
          border-radius: 0 !important;
    }

    #mh-CallList  .call-list-option > option:after {

            padding: 5px 10px !important;
            border-bottom: 2px solid #d1d1d1 !important;
            display: block !important;
            box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%) !important;
            padding: 2rem !important;
            border: 6px;
            box-shadow: 0px 0px 10px 0px !important;

        }

     
        #mh-CallList  select {

          border-bottom:1px solid #ccc !important;
          border-top:0px solid #6c7293 !important;
          border-left:0px solid #6c7293 !important;
          border-right:0px solid #6c7293 !important;
          outline:0px ;
          color:#959393 !important;
          

    }

    /*yousuf css*/

#mh-CallList .css-11unzgr [type="checkbox"]:before {
    position: relative;
    display: block;
    width: 11px;
    height: 11px;
    content: "";
}

#mh-CallList .css-11unzgr [type="checkbox"]:after {
  position: relative;
  display: block;
  border-radius:5px !important;
  left: 0px;
  top: -16px !important;
  width: 9px;
  height: 9px;
  border-width: 1px;
  border-style: solid;
  border-color: #087ab6 #0595ae #0595ae #087ab6;
  content: "";
  background-color:#fff;
  background-repeat: no-repeat;
  background-position: center;
}

#mh-CallList .css-11unzgr [type="checkbox"]:checked:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAQAAABuW59YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAB2SURBVHjaAGkAlv8A3QDyAP0A/QD+Dam3W+kCAAD8APYAAgTVZaZCGwwA5wr0AvcA+Dh+7UX/x24AqK3Wg/8nt6w4/5q71wAAVP9g/7rTXf9n/+9N+AAAtpJa/zf/S//DhP8H/wAA4gzWj2P4lsf0JP0A/wADAHB0Ngka6UmKAAAAAElFTkSuQmCC'),
   linear-gradient(135deg, #02bfa4 0%, #00d29e 100%);
   color:#fff;
}

#mh-CallList .css-11unzgr [type="checkbox"]:disabled:after {
  -webkit-filter: opacity(0.4);
}
#mh-CallList .css-11unzgr [type="checkbox"]:before {
  width: 16px;
  height: 16px;
}
#mh-CallList .css-11unzgr [type="checkbox"]:after {
  top: -20px;
  width: 16px;
  height: 16px;
}
#mh-CallList .call-list-team-member .css-1pcexqc-container {
    margin-top: -18px;
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
}   

#mh-CallList .call-list-team-member {
    margin-top: 20px;
}
#mh-CallList .css-1jllj6i-control{
	min-width: 200px;
}
#mh-CallList  span.css-1gpjby2 {
    color: #087ab5;
    margin-left: -50px;
}
#mh-CallList span.css-1gpjby2 svg {
    margin-right: -6px;
    min-width: 28px;
    width: 100%;
    height: 28px;
}

#mh-CallList button.PlusbuttonIcon {
     border: 0;
     width: 12px;
    height: 12px;
    padding: 5px;
    background-size: 10px;
    background-repeat: no-repeat;
}

#mh-CallList .PlusbuttonIconImg {

     border: 0;
     width: 12px;
    height: 12px;
    padding: 5px;
    
}
#mh-CallList .addicon {
    position: relative;
    left: -19px;
    top: 3px;
}
#mh-CallList .filter-del {
    top: 4px;
    position: relative;
}
#mh-CallList .team-list {
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

#mh-CallList .del{

    float: right;
}
#mh-CallList .team-model{

    overflow: visible;
}

#mh-CallList .well-team {

    max-height: 300px;
    padding: 19px;
    margin-bottom: 20px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
   overflow: auto;
}

#mh-CallList .del-msg-padding{

    padding:5px;
    margin-bottom: 3px;
}
#mh-CallList .yr-cancelbtn span {

    background: #fff !important;
    display: block !important;
    padding: 10px 20px !important;
    border-radius: 3px !important;
}
#mh-CallList .add-team-btn {
    color: #087ab6 !important;
    font-weight: 500;
    cursor: pointer !important;
}
#mh-CallList .calllist-btn {
    min-width: 120px;
    height: 36px !important;
    font-size: 13px;
    margin-top: 0px !important;
}
#mh-CallList .kt-checkbox.kt-checkbox--single {
    width: 9px !important;
    height: 5px !important;
}
#mh-CallList .kt-checkbox {
    display: inline-block !important;
    position: '' !important;
    padding-left: 0px !important;;
    margin-bottom: 0px !important;;
    text-align: right !important;
    cursor: pointer !important;
    font-size: 1rem !important;
 }
 #mh-CallList .kt-checkbox {
    display: inline-block;
    /* position: relative; */
    /* padding-left: 30px; */
    /* margin-bottom: 10px; */
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#mh-CallList .new-model-popup .form-group label {
    margin-top: 0px !important;
    font-size: 1.2rem;
    font-weight: 500;
    color: #848588;
}
#mh-CallList .new-model-popup .modal-body {
    padding: 1.2rem !important;
    padding-top: 0 !important;
}
#mh-CallList .mt-4, .my-4 {
    margin-top: 1.0rem !important;
}
#mh-CallList .modal-body {
    position: relative !important;
}
#mh-CallList .yr-submitbtn {
    min-width: 96px !important;
}
#mh-CallList .yr-cancelbtn span {
    background: #fff !important;
    display: block !important;
    padding: 8px 2px !important;
    border-radius: 3px !important;
}
#mh-CallList .modal-title-team-member {
    font-size: 1.8rem !important;
    padding: 1.25rem 0 0 1rem !important;
    color: #848588 !important;
    font-weight: 500 !important;
}
#mh-CallList .red{
    color: red;
}
#mh-CallList .yr-cancelbtn {
    padding: 1px;
}
#mh-CallList .yr-cancelbtn span {
    background: #fff !important;
    display: block !important;
    padding: 10px 4px !important;
    border-radius: 3px !important;
}
#mh-CallList .new-model-popup .modal-body {
    padding: 0.2rem !important;
    padding-top: 0 !important;
}
#mh-CallList .modal-content.white-bg.yr-campaigns-model {
    padding: 0px 0px !important;
    padding-bottom: 23px !important;
}
#mh-CallList .kt-checkbox > span {
    border-radius: 3px !important;
    background: none !important;
    position: absolute !important;
    top: 1px !important;
    left: 0 !important;
    height: 16px !important;
    width: 16px !important;
}
#modalContactViaID.new-model-popup .yr-campaigns-model .modal-header {
    /* border-bottom: 1px; */
}

#mh-CallList .new-model-popup .yr-campaigns-model .modal-header {
    border-bottom: 1px solid #ddd !important;
}
#mh-CallList .yr-campaigns-model .modal-header {
    padding: 0rem !important;
}
#mh-CallList .emai-content {
   
    padding: 2px !important;
    margin-top: 11px !important;
}

#mh-CallList span.css-1gpjby2 {
    color: #087ab5 !important;
    margin-left: -44px !important;
}
#mh-CallList span.css-1gpjby2 svg {
    
    min-width: 28px !important;
    width: 100% !important;
    height: 28px !important;
    margin-top: 14px !important;
    position: relative !important;
}
#mh-CallList .css-1v99tuv {
    font-family: sans-serif !important;
    margin-top: 12px !important;
    font-size: 1rem !important;
    position: relative !important;
}
#mh-CallList .modal .modal-content .kt-portlet__foot {
    border-top: 0 !important;
    padding: 21px 11px 40px 40px !important;
}
#mh-CallList .la {
   font-size: 18px !important;
}
#mh-CallList .list-team-membar{
    padding-left: 20px !important;
    padding-right: 20px !important;
}
#mh-CallList .mat-style {
    height: 36px !important;
}
    
#mh-CallList  #teamMemberCallList .css-48ayfv{
    padding: 0px !important;
}   
#mh-CallList  .mh-dis{
    pointer-events: none;
} 

#teamMemberCallList.first--checklist .css-1jllj6i-control, 
#teamMemberCallList.first--checklist .css-6y1x9t-control{
    margin :8px !important;
    min-width: unset;
}

#teamMemberCallList.first--checklist > div > button + div{
    margin-top: 12px !important
}

#teamMemberCallList.first--checklist .css-11unzgr [type="checkbox"]:after{
    border-radius: 0 !important;
}