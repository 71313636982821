/* Start Support Comments */
.ui.comments .comment .author {
  color: #999595 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.ui.comments .comment > .content {
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  padding-top: 15px;
}
.ui.form .field > label {
  color: #9f9d9d;
  font-weight: 500;
  margin-bottom: 15px;
}
.ui.minimal.comments {
  padding: 15px;
  border: 2px solid #d0d0d0;
  max-width: 100%;
  border-radius: 10px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #ccc !important;
  max-height: 450px;
  overflow: auto;
  margin: 0 0 14px 0;
}

.ui.comments .comment .comments {
  margin-left: 25px;
}

.ui.comments .comment .metadata {
  display: block !important;
  margin-left: 0 !important;
  padding-top: 2px;
  padding-bottom: 10px;
}

.ui.comments .comment .text {
  color: rgb(105 105 105 / 87%);
}
/* End Support Comments */
.yr-faicon.ticket-comment-fav-icon::before {
  width: 18px;
  height: 18px;
}
