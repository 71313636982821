.inputdata{
    
    border-bottom: 1px solid #ccc !important;
    border-top: 0px solid #6c7293 !important;
    border-left: 0px solid #6c7293 !important;
    border-right: 0px solid #6c7293 !important;
    outline: 0px;
    color: #959393 !important;
}

#first_0 > .borderset{
	border: 1px solid rgb(15, 98, 254);
}

.main > .borderset{

	border: 1px solid rgb(15, 98, 254) !important;
}
.autoicon{

	height: 1rem !important;
    width: 1rem !important;
    margin: 0 .25rem !important;
}
.review-right-action-btns {
    position: relative!important;
    margin: .5rem 0 0;
}
.r-url-not-allowed{

    cursor:not-allowed;
}
.review-yr-chat-round-box {
    padding: 19px 19px;
    border: 1px solid #dcdcdc;
    border-radius: 10px !important;
    margin-bottom: 30px;
}


/* div#first_0.cursorpointer.myAutomated{
    pointer-events: none;
} */

select.branchcount[disabled], select.direction[disabled]{
    cursor: not-allowed;
}