.customModal{
    position: relative !important;
    padding:30px !important;
    position: relative;
}
table.ui.celled.compact.table {
    border: 1px solid rgba(34, 36, 38, .15) !important;
}

table.ui.celled.compact.table td:first-child {
    min-width: 140px !important;
    width: 140px !important;
}
table.ui.celled.compact.table button.ui.small.button {
    background: transparent;
    width: auto;
} 
button.ui.blue.button.btn.linear-gradient.add-data-btn {
    width: auto;
}

.inputBorderFeild{
    border: 1px solid rgba(34, 36, 38, .15) !important;
    border-radius: .28571429rem !important;
    padding: .78571429em 1em !important;
    background: #fff !important;
    color: rgba(0, 0, 0, .87) !important;
    box-shadow: 0 0 0 0 transparent inset !important;
    transition: color .1s ease, border-color .1s ease !important;
    font-size: 1em !important;
    line-height: 1.2857 !important;
    resize: vertical !important;
    width: 100% !important;
}

.inputBorderFeild input {
    margin-left:0px !important;
    line-height: 13px;
    border: 1px solid #eee;
    border-bottom: 1px solid #eee !important;
    padding: 10px 10px;
}

.mrtop{
    margin-top: 10px !important;
}

.modalHeader{
    padding-left: 0px !important;
}
/* .newLoader{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */