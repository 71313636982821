.yr-search span.yr-searchPlace-holder {
  position: absolute;
  right: 10px;
  top: 10px;
}
.yr-search .form-control {
  padding-right: 60px;
}
.dropdownMenu {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  height: 38px;
  line-height: 34px;
  width: 180px;
  padding: 0 12px;
}
.reviewActions {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  height: 38px;
  line-height: 34px;
  width: 100px;
  padding: 0 12px;
}
.dropdownMenu i,
.reviewActions i {
  float: right;
}
.ui.selection.dropdown i {
  float: right;
  margin-right: -17px;
  color: #646c9a;
  font-size: 1em;
}
.dropdownMenu.ui.dropdown .menu {
  left: 75px;
}
.dropdownMenu .icon,
.generatedBy .icon {
  color: #646c9a;
  font-size: 1em;
  margin-left: 10px;
}
.dropdownMenu.ui.dropdown .menu > .item {
  border-bottom: 1px solid #ddd;
}
.dropdownMenu.ui.dropdown .menu > .item:hover {
  background: #fff;
}
.review-filter-header {
  display: inline-flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.review-filter-header .btn {
  color: #087ab5;
  font-weight: 500;
  padding: 0.35rem 0.3rem;
}
.ui.selection.dropdown {
  border: 1px solid #ebedf2;
}
.ui.selection.dropdown:hover,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu {
  border-color: #087ab5;
}
.generatedBy {
  margin-left: 15px;
}
.review-border-top {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #ddd;
}
.kt-widget3 .kt-widget3__item .kt-widget3__body .kt-widget3__text {
  color: #636363;
}
.ui.dropdown:not(.button) > .default.text {
  color: #646c9a;
}
.review-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.max-height-510 {
  /* max-height: 510px;
  overflow-y: auto; */
}
.kt-widget3__user-img {
  width: 3.2rem;
  border-radius: 50%;
  /* background-color: #068db1; */
  height: 3.2rem;
  position: relative;
}
@media (min-width: 1024px) {
  .border-right {
    border-right: 1px solid #ddd;
  }
}
.paddingRight {
  padding-right: 89px !important;
}
.star_custom {
  font-size: x-large;
  width: 22px;
  display: inline-block;
  color: gray;
}
.star_custom:last-child {
  margin-right: 0;
}
.star_custom:before {
  content: "\2605";
}
.star_custom.on {
  color: #f36919;
}
.star_custom.half:after {
  content: "\2605";
  color: #f36919;
  position: absolute;
  margin-left: -20px;
  width: 10px;
  overflow: hidden;
}
.requestReviewForm.ui.input>input{
  padding-left:0px !important;
}
.generatedBy .menu{
  overflow:auto;
  max-height:400px;
  z-index:9999 !important;
}

.mh-BlurrOpt{
  opacity: .4;
  pointer-events: none;
}


.no-shadow{
	-webkit-box-shadow: none !important;
	box-shadow: none !important
}

.kt-form .ui.input > input{
  padding:.78571429em 13px !important 
}

.kt-form .ui.input{
  margin-left:0 !important;
}

.custom-fix{
  max-height: 470px;
  overflow: auto;
}






.flex-header .kt-widget3__username{
  display: block;
}
 
.flex-body{
  padding-left : 5rem !important
}


.form-wrapper{
  justify-content: flex-end;
}
@media (min-width: 1440px) {
  .form-filter{
    min-width: unset !important;
    width: 100% !important;
    padding-right: 1em !important;
  }
}
@media (min-width: 1024px) {
  
  .form-search + div{
    padding-right: 0 !important;
  }
}
@media (min-width: 1024px) and (max-width: 1366px) {
  .form-filter{
    min-width: unset !important;
    width: 100%;
    position: relative;
  }

  .form-filter i{
    float: unset;
    position: absolute;
    top: 12px;
    
    right: 12px;
  }

}

.form-filter i{
  padding: 0;
  margin: -0 !important;
}

@media(max-width: 991px){
  .paddingRight.p-r0{
    padding-right: 0 !important;
  }

  
  .form-wrapper{
    justify-content: space-between;
  }
}


.csv-btn{
  min-width: 95px !important;
  font-size: 11px !important;
  height: 30px !important;
  padding: 0 !important;
  margin-bottom: 1rem;
}


/* aslam  | Your Rewview |*/
@media(max-width: 650px){
  .form-wrapper{
    flex-wrap: wrap;
    margin:  0 !important;
  }

  .form-search, .form-filter{
    width: 100%;
    margin: 0 0 .75rem;
  }

  .form-search + div.col-12{
    padding: 0;
  }

 


 

  .flex-header{
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: flex-start !important;
  }

  .flex-header{
    padding-left:1rem !important;
  }
  
  .flex-body{
    padding-left : 5.5rem !important
  }

  .flex-header .kt-widget3__status{
    text-align: left !important;
    padding-left : 4.5rem !important
  }
}

