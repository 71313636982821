.business_checkbox{
    display: flex;
    max-height: 250px;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 15px;
}

.business_box_title{
    margin-bottom: 1rem;
}

.business_checkbox__group{
    margin-bottom: 10px;
}

.form_group_input{
    margin-bottom: 0px !important;
    margin-left: 1.5rem;
}

.formgroup_lebal{
    font-size: 12px !important;
    margin-top: 0px !important;
}
.inputTextare{
    width: 100%;
    border: 1px solid #ebedf2;
    padding: 6px;
    min-height: 180px;
}

.companyOverviewTextarea{
    width: 90%;
    min-height: 200px;
}

