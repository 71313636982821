    /* manage contact 2 */
    .yr-details-analy .table tbody td:first-child {
        min-width: auto;
    }
    .yr-details-analy tbody td:first-child{
    width: 40%
    }
    .yr-selectBtnSec{
        display: flex;
        flex-wrap: wrap;
        }
    .yr-gradientBorderbtn{
        background: linear-gradient(90deg, #00d39e , #087ab6);
        box-shadow: 0px 4px 8px #b3b3b3 !important;
        border-radius: 20px;
        color: #333;
        display: inline-block;
        font-size: 16px;
        padding: 3px;
        text-decoration: none;
        min-width: 130px;
        border: 0;
    }
    .yr-details-analy .yr-gradientBorderbtn select{
        border-radius: 20px;
    }
    .yr-contactengagement ul {
        display: flex;
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
    }
    
    .yr-contactengagement ul li {
        flex: 1;
        border: 1px solid #d8d8d8;
        padding: 10px;
        text-align: center;
        border-left: 0;
        min-height: 264px;
        align-items: center;
        justify-content: center;
        display: inline-flex;
    }
    
    .yr-contactengagement ul li:first-child {
        border-left: 1px solid #d8d8d8;
    }
    .yr-deliverrates tr,.yr-deliverrates tr td {
        border: 1px solid #d8d8d8;
    }
    .yr-config-setting tbody tr td i {
        font-size: 18px;
    }
    .yr-dr-box h3 {
        font-size: 25px;
        font-weight: 600;
        line-height: 25px;
    }
    .yr-deliverrates .yr-dr-box h3 {
        font-size: 20px;
    }
    .yr-dr-box {
        text-align: center;
    }
    .pd-15 {
        padding: 15px;
    }
    .yr-dr-box strong {
        font-weight: 600;
        margin-bottom: 8px;
        display: block;
    }
    .yr-dr-box p {
        margin-bottom: 6px;
    }
    .yr-dr-box a {
        color: #02bea3;
        text-decoration: underline;
    }
    .yr-contactengagement>h3, .yr-deliverrates>h3 {
        margin-bottom: 20px;
        font-size: 16px;
        margin-top: 20px;
    }
    .yr-deliverrates, .yr-contactengagement {
        margin-bottom: 40px;
    }
    .yr-loader-area {
        padding: 15px;
    }
    .info-icon:hover .clicktoshow{
        opacity: 1;
            visibility: visible;
        }
        .clicktoshow{
            opacity: 0;
            visibility: hidden;
        position:relative;
            transition: top 0.3s cubic-bezier(0.25, 0.1, 0.11, 1.29), left 0.3s cubic-bezier(0.25, 0.1, 0.11, 1.29), opacity 0.3s ease;
        }
        .clicktoshow{
            position: absolute;
            background: #343738;
            border-radius: 4px;
            box-shadow: 0 4px 12px rgba(0,0,0,0.2);
            padding: 10px 20px;
            -webkit-font-smoothing: antialiased;
            line-height: 1.4;
            font-size: 10px;
            color: #fff;
            word-break: break-word;
            text-align: center;
            display: block;
        }
    hr.loader {
        height: 2px;
        background: #02c1a3;
    }
    .yr-manage-contact-popup .yr-cancelbtn {
        font-size: 13px;
        min-width: 120px;
        height: 43px;
        box-shadow: 0px 4px 8px #b3b3b3 !important;
        }
    .yr-manage-contact-popup .form-group {
        margin-bottom: 1rem;
    }
    .yr-manage-contact-popup .dropdown-menu.inner > li:hover > a {
        background: #f7f8fa;
        background-image: linear-gradient(45deg, #087ab6 , #00d39e) !important;
    }
    .yr-manage-contact-popup .dropdown-menu.inner > li:hover > a .text, .yr-manage-contact-popup .dropdown-menu.inner > li:hover > a .bs-ok-default.check-mark {
        color: #ffffff;    
    }
    .yr-manage-contact-popup .dropdown-menu.inner > li > a .text{
        margin-left: 15px;
        margin-right: 0px;
    }
    .yr-manage-contact-popup .dropdown-menu {
        border: 1px solid #bbbbbb !important;
        padding: 0px;
    }
    .yr-manage-contact-popup .dropdown-menu .show {
        border: 0px!important;
    }
    .yr-manage-contact-popup span.bs-ok-default.check-mark {
        position: absolute;
        top: 16px;
        display: inherit !important;
        left: 10px;
    }
    .yr-manage-contact-popup .bootstrap-select .bs-ok-default:after {
        content: '';
        display: block;
        width: .5em;
        height: .5em;
        border-style: solid;
        border-width: 0 0.16em 0.16em 0;
        transform: rotate(-45deg);
    }
    h3.sm-b-title {
        font-weight: 700;
        font-size: 18px;
    }
    table.yr-bucket-table td span {
        display: block;
        font-size: 11px;
    }
    table.yr-bucket-table td span.b {
        font-weight: 500;
    }
    table.yr-bucket-table td span.t-sm{
        font-size: 13px;
    }
    table.table.yr-bucket-table .btn {
        background: #e8e8e8;
        width: auto;
        padding: 0px 12px;
        height: 30px;
        line-height: 25px;
        margin-right: 10px;
    }
    table.table.yr-bucket-table .btn span {
        display: inline-block;
        border-left: 1px solid #f5f5f5;
        width: 5px;
        height: 27px;
        margin-left: 10px;
    }
    .yr-ep-icon {
        display: inline-flex;
    }
    .yr-ep-icon a {
        margin-right: 10px;
        color: #b3b1b1;
    }
    .yr-mc-select select {
        border: 0px;
        color: #149dd8 !important;
        font-size: 13px;
        font-weight: 500;
        padding-left: 0;
    }
    .yr-mc-select {
        display: inline-block;
        margin-right: 20px;
    }
    .hr-top {
        border-top: 2px solid #ddd;
        padding-top: 20px;
    }



    .yr-manageContacts-2 tr th{
        white-space: nowrap;
    }
    .yr-manageContacts-2 i.fa.fa-chevron-right.blue {
        margin-left: 8px;
    }
    .yr-nowrap{
        white-space: nowrap;
    }
    
    .yr-manageContacts-2 table.dataTable thead .sorting:before, .yr-manageContacts-2 table.dataTable thead .sorting_asc:before, .yr-manageContacts-2 table.dataTable thead .sorting_desc:before{
        bottom: 24px !important;
    }
    .yr-manageContacts-2 table.dataTable thead .sorting:after, .yr-manageContacts-2 table.dataTable thead .sorting_asc:after, .yr-manageContacts-2 table.dataTable thead .sorting_desc:after{
        bottom: 14px !important;
    }
    .yr-manageContacts-2 .dataTables_wrapper .dataTable th:first-child, .yr-manageContacts-2 .table tbody tr td:first-child {
        width: 30px !important;
        min-width: auto !important;
    }
    .yr-manageContacts-2 .table tbody tr td:first-child .kt-checkbox {
        width: 18px;
        height: 18px;
    }
    .yr-manage-contact2 .btn-outline-info.zy-outline {
        color: #159bd5;
        border-color: #159bd5;
        border: 2px solid;
    }
    .yr-manage-contact2 .dataTables_wrapper .dataTable th .kt-checkbox{
        margin-top: auto;
        margin-bottom: 14px;
    }
    .bck-btn a i {
        color: #1aadfb;
    }
    .bck-btn a {
        font-size: 16px;
        color: #8c8a8a;
    }
    .yr-campaigns-model h4 {
        color: #1b8dca;
        font-size: 16px;
        margin-top: 30px;
    }
    .new-model-popup .yr-campaigns-model .modal-header {
        border-bottom: 1px solid #ddd;
    }
    @media (max-width: 480px){
      .yr-ameplaybooks .nav-tabs.nav-tabs-line .nav-item {
        min-width: auto;
    }
    }
    .yr-manage-contact2 h2, p,td,th{
        color:#959393 !important;
    }
    .fa-caret-left:before {
        content: "\f0d9";
    }

    #modalContactViaID .modal-body{
        padding:0px;
    }

    #modalContactViaID.new-model-popup .yr-campaigns-model .modal-header{
        border-bottom: none;
    }

    #modalContactViaID .kt-radio > span{
        top:-10px !important;
    }

    #modalContactViaID .yr-cancelbtn span{
        padding: 10px 20px;
    }

    #AddEditContactModal .react-datepicker-wrapper{
        width:100%;
    }
    .csvColumnFields{
        margin: 5px;
        background: #635e5e;
        padding: 4px;
        border-radius: 7px;
        color: white;
        display: inline-block;
    }

    .mh-CSVbrowse{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    }

    .mh-CSVbrowseError{
        color:red;
        margin:5px
    }
    .yr-manage-contact2 .ui.table th.three.wide{
        width:8% !important;
    }

    .yr-manageContacts-2 .kt-badge.kt-badge--pill {
        line-height: .1;
    }
      /* end manage contact 2 */ 



      