.urlshortner__box {
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
    padding: 10px;
    border-radius: 10px;
}

.urlshortner__box_input {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    align-content: center;
    align-items: center;
}
.urlshortner__box__closebutton{
    display: flex;
    justify-content: end;
}

.urlshortner__box__closebutton i{
    color: red;
    border: 1px solid red;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
}