/* Start Popup Design */
#notificationSetingModalPopup .modal{overflow: auto;}
#notificationSetingModalPopup .modal-body{padding: 0px;}
#notificationSetingModalPopup .yr-col-3 {
    width: 30%;
    padding: 15px;
    position: relative;
  }
  #notificationSetingModalPopup .yr-col-3 .form-group label {
    font-size: 1rem;
  }
  #notificationSetingModalPopup.yr-col-3:nth-child(2) {
    width: 33%;  
    border-left:1px solid #ccc;
  }
  #notificationSetingModalPopup .yr-col-3:nth-child(3) {
    width: 37%;
    padding-bottom: 100px;
    border-left:1px solid #ccc;
  }
  #notificationSetingModalPopup .green-btn {
    background: #1dc9b7;
    color: #fff;
    border-radius: 0;
  }

  #notificationSetingModalPopup .yr-col-3 .kt-switch.kt-switch--success:not(.kt-switch--outline) input:checked ~ span:before, .yr-col-3 .kt-switch.kt-switch--success:not(.kt-switch--outline) input:empty ~ span:before {
    background-color: #1dc9b7 !important;
}
  #notificationSetingModalPopup .yr-line {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  #notificationSetingModalPopup .yr-popBox-1 {
    padding: 2em 3em;
  }
  #notificationSetingModalPopup .yr-popBox-2 {
    padding: 2em 4em;
  }
  #notificationSetingModalPopup .p-t2em{
  padding-top:2em;
  }
  #notificationSetingModalPopup .yr-col-3 .kt-switch.kt-switch--success:not(.kt-switch--outline) input:checked ~ span:before,.yr-col-3 .kt-switch.kt-switch--success:not(.kt-switch--outline) input:empty ~ span:before{
    background-color: #1dc9b7;
  }
  #notificationSetingModalPopup .yr-col-3 .box-title{
    color: #6c7293;
  }
  #notificationSetingModalPopup .yr-buttonOk{
    position: absolute;
    bottom: 20px;
    top: auto;
    right: 20px;
  }
  #notificationSetingModalPopup.yr-buttonOk #notificationSetingModalPopup .yr-submitbtn{
    min-width: auto;
  }
  @media (max-width: 992px){
    #notificationSetingModalPopup .yr-popBox-1 {
      padding: 2em 0em;
  }
  #notificationSetingModalPopup .yr-popBox-2 {
    padding: 2em 0em;
  }
  }
  @media (max-width: 768px){
    #notificationSetingModalPopup .yr-col-3,#notificationSetingModalPopup #notificationSetingModalPopup .yr-col-3:nth-child(2), #notificationSetingModalPopup .yr-col-3:nth-child(3){
    width: 100%;
    border-bottom: 1px solid #dedede;
    border-left: 0;
  }
  #notificationSetingModalPopup .yr-line {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  }

  #notificationSetingModalPopup .fluid #notificationSetingModalPopup .field{
    width:100%
  }

  #notificationSetingModalPopup .ui.input{
    margin-left: 0px;;
  }
  /* End Popup Design */
  